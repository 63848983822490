import React, { useEffect, useState } from "react";

const TournamentRules = () => {


  useEffect(() => {

    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{
      padding: 25,
      paddingBottom: 100,
    }}>
      <div className="bg-col pb-5">
    <div>
      <p style={{ lineHeight: '18pt', widows: 0, orphans: 0 }}>
        <strong>
          <span style={{ fontFamily: 'HelveticaNeue-Bold', fontSize: '18pt' }}>
            Albastini Tournament Official Rules
          </span>
        </strong>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>
          NO PURCHASE NECESSARY TO ENTER OR TO WIN THE TOURNAMENT. A PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING.
        </span>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>
          All participants must be register for the Tournament before the tournament start time.
        </span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>1. </span>
        <strong>
          <span style={{ fontFamily: 'HelveticaNeue-Bold', fontSize: '14pt' }}>Eligibility:</span>
        </strong>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>
          The Tournament is open only to legal residents of the 50 United States and the District of Columbia (D.C.) who are at least 18 years of age at the time of entry. Players must be physically located in the United States. Players are responsible for keeping the email address associated with the tournament valid for the duration of the tournament. The prize award is subject to verification of eligibility and compliance with the Official Rules. Employees of Albastini, LLC. (the "Sponsor") and affiliate companies, suppliers as well as the immediate family (spouse, parents, siblings, and children) and household members of each such employee are not eligible. The Tournament is subject to all applicable Federal, State, and local laws and regulations. Void where prohibited or restricted by law.
        </span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>2. </span>
        <strong>
          <span style={{ fontFamily: 'HelveticaNeue-Bold', fontSize: '14pt' }}>Sponsor:</span>
        </strong>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>
          Albastini, LLC, 450 Century Parkway, Suite 250, Allen, Texas 75013.
        </span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>3. </span>
        <strong>
          <span style={{ fontFamily: 'HelveticaNeue-Bold', fontSize: '14pt' }}>Timing:</span>
        </strong>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>
          The Tournament begins at the posted start time and ends when there is only one player remaining.
        </span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>4. </span>
        <strong>
          <span style={{ fontFamily: 'HelveticaNeue-Bold', fontSize: '14pt' }}>How to Enter:</span>
        </strong>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>
          There is no purchase necessary to participate in the Tournament. A purchase does not increase your chances of winning. To enter the Tournament, download the Albastini Card Game App from{' '}
        </span>
        <u>
          <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>http://albastini.com</span>
        </u>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>
          {' '}
          and register for an account. Login using your registered account and click on the Tournament icon. All players are required to read, understand, and agree to the Official Rules prior to registering for the Tournament.
        </span>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>5. </span>
        <strong>
          <span style={{ fontFamily: 'HelveticaNeue-Bold', fontSize: '14pt' }}>How to Win:</span>
        </strong>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>
          {' '}
          The undefeated player at the end of the tournament will be declared the winner.
        </span>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>6. </span>
        <strong>
          <span style={{ fontFamily: 'HelveticaNeue-Bold', fontSize: '14pt' }}>Prize:</span>
        </strong>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>
          {' '}
          The winner will receive Albastini Game Coins.
        </span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>7. </span>
        <strong>
          <span style={{ fontFamily: 'HelveticaNeue-Bold', fontSize: '14pt' }}>Winner Verification:</span>
        </strong>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>
          {' '}
          Receiving the prize is contingent upon compliance with these Official Rules.
        </span>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>8. </span>
        <strong>
          <span style={{ fontFamily: 'HelveticaNeue-Bold', fontSize: '14pt' }}>
            Release/Indemnification and Limitation of Liability:
          </span>
        </strong>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>
          {' '}
          By entering the Tournament, each player agrees to release and hold harmless the Sponsor, Facebook Inc., Twitter Inc., Apple Inc., and their respective subsidiaries, affiliates, suppliers, distributors, advertising/promotion agencies and each of their respective parent companies and each such company’s officers, directors, employees and agents (collectively, the “Released Parties”) from and against any claim or cause of action, including, but not limited to, personal injury, death, or damage to or loss of property, arising out of participation in the Tournament or receipt or use or misuse of the prize.
        </span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
      </p>
      <p style={{ lineHeight: '17pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>9. </span>
        <strong>
          <span style={{ fontFamily: 'HelveticaNeue-Bold', fontSize: '14pt' }}>Publicity:</span>
        </strong>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>
          {' '}
          Except where prohibited, by participating in the Tournament and accepting the prize you grant the Sponsor and their designees the right to use of your name, photograph, likeness, statements, biographical information, voice, city, and State address for advertising and promotional purposes in any media, worldwide, without further payment or consideration. YOU FURTHER ACKNOWLEDGE THAT IF YOU ARE CHOSEN AS A WINNER, YOUR PERSONAL IDENTIFYING INFORMATION MAY BE DISCLOSED TO THIRD PARTIES INCLUDING, WITHOUT LIMITATION, PLACING YOUR NAME ON A WINNERS LIST.
        </span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>10. </span>
        <strong>
          <span style={{ fontFamily: 'HelveticaNeue-Bold', fontSize: '14pt' }}>General Conditions:</span>
        </strong>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>
          {' '}
          Sponsor reserves the right to cancel, suspend and/or modify the Tournament, or any part of it, if any fraud, loophole, technical failures, human error, or any other factor impairs the integrity or proper functioning of the Tournament, as determined by the Sponsor in its sole discretion. Sponsor reserves the right, in its sole discretion, to disqualify any individual it finds to be tampering with the entry process or the operation of the Tournament or the Albastini Card Game or to be acting in violation of the Official Rules of this or any other promotion or in an unsportsmanlike or disruptive manner. Any attempt by any person to deliberately undermine the legitimate operation of the Tournament or the Albastini Card Game may be a violation of criminal and civil law, and, should such an attempt be made, the Sponsor reserves the right to seek damages from any such person to the fullest extent permitted by law. Sponsor’s failure to enforce any term of these Official Rules shall not constitute a waiver of that provision.
        </span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>&#xa0;</span>
      </p>
      <p style={{ lineHeight: '16pt', widows: 0, orphans: 0 }}>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>11. </span>
        <strong>
          <span style={{ fontFamily: 'HelveticaNeue-Bold', fontSize: '14pt' }}>List of Winner(s):</span>
        </strong>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>
          {' '}
          For a winner list, visit{' '}
        </span>
        <u>
          <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>http://albastini.com</span>
        </u>
        <span style={{ fontFamily: 'HelveticaNeue', fontSize: '14pt' }}>
          . The winner list will be posted as soon as the winner verification process is complete.
        </span>
      </p>
      <p style={{ bottom: '10px', right: '10px', position: 'absolute' }}>
        <a href="https://wordtohtml.net" target="_blank" style={{ fontSize: '11px', color: '#d0d0d0' }}>
          Converted to HTML with WordToHTML.net
        </a>
      </p>
    </div>

      </div>
    </div>
  );
};
export default TournamentRules;

