import React, { useState } from "react";
import { Layout, Menu } from "antd";
import { useLocation } from "react-router";
import { Link, useNavigate } from "react-router-dom";
import images from "../themes/appImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUnlock,
  faUser,
  faTrophy,
  faBell,
  faSignOutAlt,
  faAngleLeft,
  faAngleRight,
  faShoppingBag,
  faBan,
  faGamepad,
  faDiagnoses,
  faCodeBranch,
} from "@fortawesome/free-solid-svg-icons";
import { logout } from "../../src/redux/Action/Auth";
import { useDispatch } from "react-redux";
import { Tooltip, Modal } from "antd";
const { Sider } = Layout;

const Sidebar = (props) => {
  let navigate = useNavigate();
  let location = useLocation();
  const dispatch = useDispatch();

  const [payload, setPayload] = useState({
    offset: 0,
    limit: 10,
    sort: null,
    order: null,
    search: "",
  });

  const onMenuClick = (e) => {
    if (
      e.key == "/usermanagement" ||
      e.key == "/gamemanagement" ||
      e.key == "/notificationmanagement" ||
      e.key == "/tournamentmanagement" ||
      e.key == "/errorlogs"
    ) {
      const searchParams = new URLSearchParams({
        offset: payload.offset,
        limit: payload.limit,
        sort: payload.sort,
        order: payload.order,
        search: payload.search,
      });

      navigate(`${e.key}?${searchParams.toString()}`);
    } else {
      navigate(e.key);
    }
    // history.push(e.key);
  };

  const disableModel = (record) => {
    Modal.confirm({
      title: "Logout",
      // icon: <img className="moale-image" src={images.alert} />,
      content: (
        <div className="">
          <p> Are you sure,you want to logout?</p>
        </div>
      ),
      okText: "Yes",
      centered: true,
      cancelText: "No",
      cancelButtonProps: {
        style: { cursor: "pointer" },
        className: "cancel-btn",
      },
      onOk() {
        dispatch(logout());
      },
      onCancel() {},
      className: "logout-modal",
      // wrapClassName: "new-button",
    });
  };

  let activeKey = "";
  switch (location.pathname) {
    case "/dashboard":
      activeKey = "/dashboard";
      break;
    case "/changepassword":
      activeKey = "/changepassword";
      break;

    case "/errorlogs":
      activeKey = "/errorlogs";
      break;

    case "/viewErrorLogs":
      activeKey = "/errorlogs";
      break;

    case "/tournamentmanagement":
      activeKey = "/tournamentmanagement";
      break;

    case "/tournamentlogs":
      activeKey = "/tournamentmanagement";
    case "/viewtournament":
      activeKey = "/tournamentmanagement";
      break;

    case "/edittournamentdetail":
      activeKey = "/tournamentmanagement";
      break;

    case "/addtournamentDetail":
      activeKey = "/tournamentmanagement";
      break;
    case "/usermanagement":
      activeKey = "/usermanagement";
      break;

    case "/economylogs":
      activeKey = "/usermanagement";
      break;

    case "/usesrdetail":
      activeKey = "/usermanagement";
      break;
    case "/edituserdetail":
      activeKey = "/usermanagement";
      break;
    case "/notificationmanagement":
      activeKey = "/notificationmanagement";
      break;
    case "/addnotification":
      activeKey = "/notificationmanagement";
      break;
    case "/editNotification":
      activeKey = "/notificationmanagement";
      break;

    //
    case "/gamemanagement":
      activeKey = "/gamemanagement";
      break;
    case "/gamelog":
      activeKey = "/gamemanagement";
      break;
    case "/shopmanagement":
      activeKey = "/shopmanagement";
      break;
    case "/versionmanagement":
      activeKey = "/versionmanagement";
      break;
    case "/createtheme":
      activeKey = "/shopmanagement";
      break;
    case "/createcard":
      activeKey = "/shopmanagement";
      break;
    case "/createemoji":
      activeKey = "/shopmanagement";
      break;
    case "/editTheme":
      activeKey = "/shopmanagement";
    case "/editCard":
      activeKey = "/shopmanagement";
    case "/editEmoji":
      activeKey = "/shopmanagement";
    case "/shopDetail":
      activeKey = "/shopmanagement";
      break;

    default:
      activeKey = "/dashboard";
  }

  return (
    <Sider
      // breakpoint="lg"
      className={props.isVisible ? "sider_left new closedd" : "sider_left new"}
      collapsedWidth="0"
      width={props.isVisible ? "108" : "250"}
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <div className="logo">
        <Link to="/dashboard">
          <img src={images.login2} alt="" />
        </Link>
      </div>
      <div className="admin-text">
        <h2>Admin Panel</h2>
      </div>

      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={[activeKey]}
        selectedKeys={[activeKey]}
        onClick={(e) => onMenuClick(e)}
      >
        <Menu.Item key="/dashboard">
          <Tooltip
            placement="right"
            title={props.isVisible ? "Dashboard" : null}
          >
            <Link to="">
              <FontAwesomeIcon icon={faHome} />
              <span className="line_dash">Dashboard</span>
            </Link>
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="/usermanagement">
          <Tooltip
            placement="right"
            title={props.isVisible ? "User Management" : null}
          >
            <Link to="">
              <FontAwesomeIcon icon={faUser} />
              <span className="line_dash">User Management</span>
            </Link>
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="/gamemanagement">
          <Tooltip
            placement="right"
            title={props.isVisible ? "Game Management" : null}
          >
            <Link to="">
              <FontAwesomeIcon icon={faGamepad} />
              <span className="line_dash">Game Management</span>
            </Link>
          </Tooltip>
        </Menu.Item>
        <Menu.Item key="/shopmanagement">
          <Tooltip
            placement="right"
            title={props.isVisible ? "Shop Management" : null}
          >
            <Link to="">
              <FontAwesomeIcon icon={faShoppingBag} />
              <span className="line_dash">Shop Management</span>
            </Link>
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="/errorlogs">
          <Tooltip
            placement="right"
            title={props.isVisible ? "error logs" : null}
          >
            <Link to="">
              <FontAwesomeIcon icon={faBan} />
              <span className="line_dash">Error Logs</span>
            </Link>
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="/versionmanagement">
          <Tooltip
            placement="right"
            title={props.isVisible ? "Version Management" : null}
          >
            <Link to="">
              <FontAwesomeIcon icon={faCodeBranch} />
              <span className="line_dash">Version Management</span>
            </Link>
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="/tournamentmanagement">
          <Tooltip
            placement="right"
            title={props.isVisible ? "Tournament Management" : null}
          >
            <Link to="">
              <FontAwesomeIcon icon={faTrophy} />
              <span className="line_dash">Tournament management</span>
            </Link>
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="/notificationmanagement">
          <Tooltip
            placement="right"
            title={props.isVisible ? "Notification Management" : null}
          >
            <Link to="">
              <FontAwesomeIcon icon={faBell} />
              <span className="line_dash">Notification Management</span>
            </Link>
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="/changepassword">
          <Tooltip
            placement="right"
            title={props.isVisible ? "Change Password" : null}
          >
            <Link to="">
              <FontAwesomeIcon icon={faUnlock} />
              <span className="line_dash">Change Password</span>
            </Link>
          </Tooltip>
        </Menu.Item>

        <Menu.Item key="#" onClick={disableModel}>
          <Tooltip placement="right" title={props.isVisible ? "Logout" : null}>
            <Link to="">
              <FontAwesomeIcon icon={faSignOutAlt} />
              <span className="line_dash"> Logout</span>
            </Link>
          </Tooltip>
        </Menu.Item>
      </Menu>
      <div className="angle-btn">
        <button type="submit" onClick={props.handlewClick} className=" btn ">
          <FontAwesomeIcon
            className="icon-angle-left"
            icon={props.isVisible ? faAngleRight : faAngleLeft}
          />
        </button>
      </div>
    </Sider>
  );
};
export default Sidebar;
