import { reduxConstant } from "../../constants/appconstant"

const intialState = {
    isLoading: false,
    tournamentList: {},
    tournamentDetail: {},
    
}

export const tournamentReducer = (state = intialState, action) => {

    switch (action.type) {

        // Add tournament
        case reduxConstant.TOURNAMENT_ADD_LOAD: return { ...state, isLoading: true, };
        case reduxConstant.TOURNAMENT_ADD_SUCCESS: return { ...state, isLoading: false };

        // Tournament list
        case reduxConstant.TOURNAMENT_LIST_LOAD: return { ...state, isLoading: true, };
        case reduxConstant.TOURNAMENT_LIST_SUCCESS: return { ...state, isLoading: false, tournamentList: { list: action?.result?.tournamentList, pagination: action?.result?.paginationData } };

        // Tournament BLock
        case reduxConstant.TOURNAMENT_BLOCK_LOAD: return { ...state, isLoading: true, };
        case reduxConstant.TOURNAMENT_BLOCK_SUCCESS: return {
            ...state,
            isLoading: false,
            tournamentList: {
                ...state.tournamentList,
                list: state.tournamentList.list.map(i => {
                    if (i._id === action.data.id) i.isBlock = !i.isBlock
                    return i
                })
            }
        };

        /// tournament logs 

        case reduxConstant.TOURNAMENT_LOG_LOAD : return {...state , isLoading : true};
        case reduxConstant.TOURNAMENT_LOG_SUCCESS : 
        console.log(action ," this is action");
        return {...state , tournamentList : action?.result, isLoading : false};
        case reduxConstant.TOURNAMENT_LOG_FAIL : return {...state , isLoading : false};

        // Tournament details
        case reduxConstant.TOURNAMENT_DETAILS_LOAD: return { ...state, isLoading: true, };
        case reduxConstant.TOURNAMENT_DETAILS_SUCCESS: return { ...state, isLoading: false, tournamentDetail: action?.result };

        // Add tournament
        case reduxConstant.TOURNAMENT_EDIT_LOAD: return { ...state, isLoading: true, };
        case reduxConstant.TOURNAMENT_EDIT_SUCCESS: return { ...state, isLoading: false };

        // Failure
        case reduxConstant.TOURNAMENT_MANAGEMENT_FAILED: return { ...state, isLoading: false };

        default: return { ...state }
    }
}