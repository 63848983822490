import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import appconstant from "../../themes/appconstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb, Image } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { viewErrorLogsAction } from "../../redux/Action/Auth";
import { reduxConstant } from "../../constants/appconstant";

const ViewErrorLogs = () => {
  const dispatch = useDispatch();
  const { errorData } = useSelector((state) => state.gameManagementReducer);
  const location = useLocation();

  useEffect(() => {
    dispatch(viewErrorLogsAction(location?.state?.data)); // Dispatch the action to load error data
  }, [dispatch, location]);

  useEffect(() => {
    document.title = "Albastini";
    window.scrollTo(0, 0);
  }, []);

  const { error, tournament, user } = errorData || {};

  const renderValue = (value) => (value ? value : "N/A");

  const isObjectEmpty = (obj) => !obj || Object.keys(obj).length === 0;

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/errorlogs">Error Logs</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>View Error Logs</Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="site-layout-background"
        style={{
          padding: 24,
          minHeight: 360,
        }}
      >
        <div className="content-e" style={{ padding: "20px" }}>
          <div className="center">
            <h6 className="text-white text-capitalize ps-3">Error Details</h6>
            <h4 style={{ color: "red", fontSize: "18px", textAlign: "center" }}>
              {renderValue(error)} {/* Display the error message */}
            </h4>
          </div>

          <div className="grid-gap">
            {/* Render Tournament Information only if tournament exists */}
            {!isObjectEmpty(tournament) && (
              <div>
                <h6 className="text-white text-capitalize ps-3">
                  Tournament information :
                </h6>
                <p style={{ fontSize: "16px" }}>
                  <strong>Name:</strong> {renderValue(tournament?.name)}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>Description:</strong>
                  {tournament?.description ? (
                    <Image
                      src={`${reduxConstant.IMAGE_URL}/${tournament?.description}`}
                      alt="Tournament Description"
                      width={70}
                      height={70}
                      preview={true}
                      style={{ borderRadius: "2px" }}
                    />
                  ) : (
                    "N/A"
                  )}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>Date/Time:</strong>{" "}
                  {renderValue(new Date(tournament?.dateTime).toLocaleString())}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>Total Players:</strong>{" "}
                  {renderValue(tournament?.totalPlayers)}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>Register Coins:</strong>{" "}
                  {renderValue(tournament?.registerCoins)}
                </p>
              </div>
            )}

            {/* Render Player Information only if user exists */}
            {!isObjectEmpty(user) && (
              <div>
                <h6 className="text-white text-capitalize ps-3">
                  Player Information:
                </h6>
                <p style={{ fontSize: "16px" }}>
                  <strong>Username:</strong> {renderValue(user?.userName)}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>Full Name:</strong> {renderValue(user?.fullName)}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>Email:</strong> {renderValue(user?.email)}
                </p>
              </div>
            )}

            {/* Render Match Information only if matchDetail exists */}
            {!isObjectEmpty(errorData?.matchDetail) && (
              <div>
                <h6 className="text-white text-capitalize ps-3">
                  Match Information
                </h6>
                <p style={{ fontSize: "16px" }}>
                  <strong>Match ID:</strong>{" "}
                  {renderValue(errorData?.matchDetail?._id)}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>First Player:</strong>{" "}
                  {renderValue(errorData?.matchDetail?.firstPlayer)}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>Second Player:</strong>{" "}
                  {renderValue(errorData?.matchDetail?.secondPlayer)}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>Winner:</strong>{" "}
                  {renderValue(errorData?.matchDetail?.winner)}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>Game Type:</strong>{" "}
                  {renderValue(errorData?.matchDetail?.gameType)}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>Game Draw:</strong>{" "}
                  {renderValue(errorData?.matchDetail?.gameDraw ? "Yes" : "No")}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>Friends Mode:</strong>{" "}
                  {renderValue(
                    errorData?.matchDetail?.friendsMode ? "Yes" : "No"
                  )}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>Coins:</strong>{" "}
                  {renderValue(errorData?.matchDetail?.coins)}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>Start Time:</strong>{" "}
                  {renderValue(
                    new Date(errorData?.matchDetail?.startTime).toLocaleString()
                  )}
                </p>
                <p style={{ fontSize: "16px" }}>
                  <strong>End Time:</strong>{" "}
                  {renderValue(
                    new Date(errorData?.matchDetail?.endTime).toLocaleString()
                  )}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewErrorLogs;
