import React, { useState, useEffect } from "react";
import { Pagination, Select, Spin } from "antd";
import images from "../../themes/appImage";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal } from "antd";
import appconstant from "../../themes/appconstant";
import { useDispatch, useSelector } from "react-redux";
import { getGameListAction, shopListAction } from "../../redux/Action/Auth";
import { reduxConstant } from "../../constants/appconstant";
import { addItemSchema, removeEmojisForSearch } from "../../common/validation";
import { useFormik } from "formik";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

const ShopManagement = () => {
  const dispatch = useDispatch();
  const gameListData = useSelector(
    (state) => state.gameManagementReducer.gameList.list
  );
  const paginationData = useSelector(
    (state) => state.gameManagementReducer.gameList.paginationData
  );

  const loading = useSelector((state) => state.gameManagementReducer.isLoading);

  const [modalVisible, setModalVisible] = useState(false);
  const [payloadImage, setPayloadImage] = useState(null);
  const [image, setImage] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [payload, setPayload] = useState({
    offset: Number(searchParams.get("offset")) || 0,
    limit: Number(searchParams.get("limit")) || 10,
    sort: searchParams.get("sort") || "null",
    order: Number(searchParams.get("order")) || "null",
    search: searchParams.get("search") || "",
  });
  const [searchValue, setSearchValue] = useState(payload.search);

  const handleChange = (col, page) => {
    const tempPayload = { ...payload };

    if (col && col.columnKey) {
      tempPayload["sort"] = col?.columnKey || payload?.sort;
      tempPayload["order"] =
        col?.order === "ascend"
          ? 1
          : col?.order === "descend"
          ? -1
          : payload?.order;
    } else if (page) {
      tempPayload["offset"] = (page - 1) * 10;
    }

    setPayload({ ...tempPayload, search: searchValue });
    const newParams = new URLSearchParams({
      ...tempPayload,
      search: searchValue,
    });
    setSearchParams(newParams);
    dispatch(
      getGameListAction({ ...tempPayload, search: searchParams.get("search") })
    ); // dispatch to get game list
  };

  useEffect(() => {
    if (searchValue?.length <= 50) {
      dispatch(
        getGameListAction({ ...payload, search: searchValue, offset: 0 })
      );
    }
  }, [searchValue]);

  useEffect(() => {
    const newParams = new URLSearchParams(payload);
    setSearchParams(newParams);
  }, [payload]);

  const handleSearch = (e) => {
    const searchData = removeEmojisForSearch(e?.target?.value);
    if (!searchData?.startsWith(" ")) {
      const newParams = new URLSearchParams({
        ...payload,
        search: e?.target?.value,
        offset: 0,
      });
      setSearchParams(newParams);
      setSearchValue(searchData);
    }
  };

  const formik = useFormik({
    initialValues: { itemName: "", cost: "", itemType: "", imageUrl: "" },
    enableReinitialize: true,
    validationSchema: addItemSchema,
    onSubmit: (values) => {
      let payload = new FormData();
      payload.append("itemName", values.itemName);
      payload.append("cost", values.cost);
      payload.append("imageUrl", payloadImage);
    },
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const initialPayload = {
      offset: Number(queryParams.get("offset")) || 0,
      limit: Number(queryParams.get("limit")) || 10,
      sort: queryParams.get("sort") || null,
      order: Number(queryParams.get("order")) || null,
      search: queryParams.get("search") || "",
    };
    setPayload(initialPayload);
    setSearchValue(initialPayload.search);
    dispatch(getGameListAction(initialPayload));
  }, []);

  const imageHandle = (e) => {
    if (e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setPayloadImage(e.target.files[0]);
    }
  };

  const hideModal = () => {
    formik.resetForm();
    setModalVisible(false);
  };

  const dataSource = gameListData?.map((e) => {
    return {
      _id: e?._id,
      game_id: e?._id,
      first_player: e?.playerOne,
      second_player: e?.playerTwo,
      start_date_time: e?.startTime,
      endTime: e?.endTime,
      game_amount_coins: e.coins,
      winner_name: e?.winner || "",
      game_type: e?.type,
    };
  });

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",

      align: "left",
      render: (value, item, index) =>
        (paginationData?.currentPage - 1) * 10 + (index + 1),
    },
    {
      title: "Game ID",
      dataIndex: "gameid",
      key: "gameid",
      sorter: false,
      render: (title, data, index) => <>{data?.game_id || "N/A"}</>,
    },
    {
      title: "First player",
      dataIndex: "firstplayer",
      key: "firstplayer",
      sorter: false,
      render: (title, data, index) => <>{data?.first_player || "N/A"}</>,
    },
    {
      title: "Second player",
      dataIndex: "secondplayer",
      key: "secondplayer",
      sorter: false,
      render: (title, data, index) => <>{data?.second_player || "N/A"}</>,
    },
    {
      title: "Start Date & Time",
      dataIndex: "startTime",
      key: "startTime",
      sorter: true,
      render: (title, data, index) => (
        <>{moment(data?.start_date_time).format("DD/MM/YYYY HH:mm") || "N/A"}</>
      ),
    },
    {
      title: "End Date & Time.",
      dataIndex: "endTime",
      key: "endTime",
      sorter: true,
      render: (title, data, index) => (
        <>{moment(data?.endTime).format("DD/MM/YYYY HH:mm") || "N/A"}</>
      ),
    },
    {
      title: "Game amount (Coins)",
      dataIndex: "coins",
      key: "coins",
      sorter: true,
      render: (title, data, index) => <>{data?.game_amount_coins}</>,
    },
    {
      title: "Winner name",
      dataIndex: "winnername",
      key: "winnername",
      render: (title, data, index) => <>{data?.winner_name || "N/A"}</>,
    },
    {
      title: "Game type",
      dataIndex: "gametype",
      key: "gametype",
      render: (title, data, index) => <>{data?.game_type || "N/A"}</>,
    },
    {
      title: "Actions",
      dataIndex: "Posted",
      key: "Posted",
      render: (value, item, index) => {
        return (
          <div>
            <Link to="/gamelog" state={item?._id}>
              <button className="tour-btn" type="submit">
                Game Logs
              </button>
            </Link>
          </div>
        );
      },
    },
  ];

  // Set the initial state from query params when the page loads

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.GameManagement}</Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.GameManagement}
            </h6>
            <input
              type="text"
              onChange={handleSearch}
              className="iocn-search  search-bar"
              placeholder="Search"
              value={searchValue}
            />
          </div>

          <Table
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            showSorterTooltip={false}
            pagination={false}
            onChange={(x, y, col) => handleChange(col)}
            // pagination={{
            //   current: paginationData?.currentPage,
            //   pageSize: paginationData?.limit,
            //   total: paginationData?.totalCount,
            //   onChange: (page) => handleChange(null, page),
            // }}
          />
          <Pagination
            showSizeChanger={false}
            showTitle={false}
            className="pagination-wrapper"
            total={paginationData?.totalCount}
            current={paginationData?.currentPage}
            onChange={(page) => handleChange(null, page)}
          />
        </div>
      </div>
    </div>
  );
};

export default ShopManagement;
