import { APIMethod } from "./methods";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

// const headers1 = {
//     "Content-Type": "multipart/form-data; boundary=12345",
//     Accept: "application/json",
// }

const multipartHeaders = {
  "access-control-allow-origin": "*",
  "Content-Type": "multipart/form-data",
  Accept: "application/json",
};

//***********************************************  AUTH ***************************************************************/

//login
export const LoginApi = (data) => {
  return APIMethod.POST("admin/login", JSON.stringify(data), headers);
};

export const forgotApi = (data) => {
  return APIMethod.POST("admin/forgot", JSON.stringify(data), headers);
};

export const dashboardApi = () => {
  return APIMethod.GET("admin/dashboard", null, headers);
};

export const logoutApi = () => {
  return APIMethod.GET("admin/logout", null, headers);
};

export const changePasswordApi = (data) => {
  return APIMethod.POST("admin/change-password", data, headers);
};

export const linkValidApi = (data) => {
  return APIMethod.POST("admin/forgot-link", data, headers);
};

export const resetPasswordApi = (data) => {
  return APIMethod.POST("admin/reset", data, headers);
};

export const sendNotificationApi = (data) => {
  return APIMethod.POST("admin/notification", data, headers);
};

export const userListApi = (data) => {
  return APIMethod.POST("admin/user-list", data, headers);
};

export const blockUserApi = (data) => {
  return APIMethod.PUT(`admin/block/${data}`, headers);
};

export const viewUserApi = (data) => {
  return APIMethod.GET(`admin/view/${data}`, headers);
};

export const editUserApi = (data) => {
  return APIMethod.PUT("admin/user-edit", data, headers);
};

export const shopListApi = (data) => {
  return APIMethod.POST("admin/shop-list", data, headers);
};

export const viewShopApi = (data) => {
  return APIMethod.GET(`admin/shop-view/${data}`, headers);
};

export const addItemApi = (data) => {
  return APIMethod.GET("admin/add-shop-item", headers);
};

export const notificationList = (data) => {
  return APIMethod.POST("admin/notification-list", data, headers);
};

export const notificationEdit = (data) => {
  return APIMethod.PUT("admin/notification-edit", data, headers);
};

export const notificationDelete = (data) => {
  return APIMethod.DELETE("admin/notification-delete", data, headers);
};

export const addTournament = (data) =>
  APIMethod.POST("admin/add-tournament", data, multipartHeaders);

export const tournamentList = (data) =>
  APIMethod.POST("admin/tournament-admin-list", data, headers);

export const tournamentDetails = (data) =>
  APIMethod.POST("admin/tournament-detail", data, headers);

export const tournamentBlock = (data) =>
  APIMethod.GET(`admin/tournament-block/${data.id}`, headers);

export const tournamentEdit = (data) =>
  APIMethod.PUT(`admin/tournament-edit`, data, headers);

export const addShopDataApi = (data) =>
  APIMethod.POST(`admin/shop-add`, data, headers);

export const getShopDataApi = (data) =>
  APIMethod.POST(`admin/shop-list`, data, headers);

export const userAddCoinApi = (data) =>
  APIMethod.PUT("admin/add-coins", data, headers);

export const getGameList = (data) => {
  if (data.sort) {
    return APIMethod.GET(
      `admin/game-list?offset=${data.offset}&limit=${data.limit}&search=${data.search}&sort=${data.sort}&order=${data.order}`,
      null,
      headers
    );
  } else {
    return APIMethod.GET(
      `admin/game-list?offset=${data.offset}&limit=${data.limit}&search=${data.search}`,
      null,
      headers
    );
  }
};

export const getErrorList = (data) => {
  if (data.sort) {
    return APIMethod.GET(
      `admin/error-log?offset=${data.offset}&limit=${data.limit}&search=${data.search}&sort=${data.sort}&order=${data.order}`,
      null,
      headers
    );
  } else {
    return APIMethod.GET(
      `admin/error-log?offset=${data.offset}&limit=${data.limit}&search=${data.search}`,
      null,
      headers
    );
  }
};

export const shopEditApi = (data) =>
  APIMethod.PUT("admin/shop-edit ", data, headers);

// economy logs Api

export const economylogsApi = (data) =>
  APIMethod.POST(`admin/economy-logs`, data, headers);

// Version Management
export const versionDetailsApi = () =>
  APIMethod.GET("admin/get-version", null, headers);

export const versionUpdateApi = (data) =>
  APIMethod.PUT("admin/update-version", data, headers);

// tournament logs api
export const tournamentLogsApi = (data) =>
  APIMethod.POST("admin/tournament-logs", data, headers);

//

//// Error logs

export const viewErrorLogsApi = (data) =>
  APIMethod.GET(`admin/view-error/${data}`);

export const gameLogsApi = (data) => {
  return APIMethod.GET(`admin/game-logs?gameId=${data.gameId}`, null, headers);
};
