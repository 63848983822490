import React, { useState, useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import appconstant from "../../themes/appconstant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from 'antd';
import images from '../../themes/appImage'
import { reduxConstant } from '../../constants/appconstant'
import { useDispatch, useSelector } from "react-redux";
import { viewUserAction, editUserAction } from "../../redux/Action/Auth";
import { useFormik } from 'formik'
import * as Yup from 'yup'; // Import Yup for validation

const EditUserDetail = () => {

    useEffect(() => {
        document.title = 'Albastini';
        window.scrollTo(0, 0)
    }, [])

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetail = useSelector(x => x.userManagementReducer?.userDetail);
    const location = useLocation();

    const [image, setImage] = useState(null)
    const [payloadImage, setPayloadImage] = useState(null)

    useEffect(() => {
        dispatch(viewUserAction(location?.state))
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const [initialValues, setInitialValues] = useState({ userName: '', fullName: '' });

    const imageHandle = (e) => {
        if (e.target.files[0]) {

            setImage(URL.createObjectURL(e.target.files[0]))
            setPayloadImage(e.target.files[0])
        }
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            userName: Yup.string().required("User Name is required."), // Adding required validation for User Name
            fullName: Yup.string().required("Full Name is required."), // Adding required validation for Full Name
        }),
        onSubmit: (values) => {
            let payload = new FormData()

            payload.append('userId', location?.state)
            payload.append('userName', values.userName)
            payload.append('fullName', values.fullName)
            payload.append('profileImage', payloadImage)

            dispatch(editUserAction(payload, navigate))
        }
    })

    useEffect(() => {
        setInitialValues({ userName: userDetail?.userName ? userDetail?.userName : '', fullName: userDetail?.fullName ? userDetail?.fullName : '' })
    }, [userDetail]);// eslint-disable-line react-hooks/exhaustive-deps

    return (

        <div>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/usermanagement"> {appconstant.UserManagement} </Link></Breadcrumb.Item>
                <Breadcrumb.Item>{appconstant.editUser}</Breadcrumb.Item>
            </Breadcrumb>
            <form className="site-layout-background" onSubmit={formik.handleSubmit} style={{ padding: 24, minHeight: 360 }}>
                <div className="content-e">
                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                        <h6 className="text-white text-capitalize ps-3">{appconstant.editUser}</h6>
                        <input type="text" className="search-bar" placeholder="Search" style={{ visibility: "hidden" }} />

                    </div>


                    <div className="datacenter">

                        <label htmlFor="file-input" className="user-detail-image-label" >
                            <div className="datacenter-icon">
                                <img alt="" src={images.pluss} title="Click to edit image" />
                            </div><img alt="" src={
                                !image ? userDetail?.profileImage ?userDetail?.profileImage.includes("https")? userDetail?.profileImage:  `${reduxConstant.IMAGE_URL}/${userDetail?.profileImage}` :userDetail.avatar?images[userDetail.avatar] :images.dummyUser : image}
                                style={{ width: '105px', height: '100px',borderRadius:"100%" }} />
                        </label>
                        <input id="file-input" type="file" onChange={imageHandle} className="hidden" accept="image/png, image/jpg, image/jpeg" />
                    </div>

                    <div className="wrapper_line">

                        <div className="form-group">
                            <label>{appconstant.name}</label>
                            <input className='form-control' type='text' placeholder="User Name" name='userName'
                                onChange={(e) => {
                                    if (e.target.value.startsWith(' ') || ! /^[A-Za-z0-9 ]*$/.test(e.target.value)) e.preventDefault()
                                    else formik.handleChange(e)
                                }}
                                value={formik.values.userName}>

                            </input>

                        </div>
                        {formik.touched.userName && formik.errors.userName && (
                                <div className="form-validation">{formik.errors.userName}</div>
                            )}
                        <div className="form-group">
                            <label>{appconstant.fullName}</label>
                            <input className='form-control' type='text' placeholder="Full Name" name='fullName'
                                onChange={(e) => {
                                    if (e.target.value.startsWith(' ') || ! /^[A-Za-z0-9 ]*$/.test(e.target.value)) e.preventDefault()
                                    else formik.handleChange(e)
                                }}
                                value={formik.values.fullName}>

                            </input>
                           
                        </div>
                        {formik.touched.fullName && formik.errors.fullName && (
                                <div className="form-validation">{formik.errors.fullName}</div>
                            )}

                        <div className="form-group">
                            <label>{appconstant.EmailAddress}</label>
                            <input className='form-control' type='text' placeholder="Email Name"
                                disabled={true}
                                value={userDetail?.email ? userDetail?.email : 'N/A'} >
                            </input>
                        </div>

                        <div className="center-align">
                            <button type="submit" className="button-list">{appconstant.update}</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    )
}
export default EditUserDetail;
