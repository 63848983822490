import React, { useEffect, useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "antd";
import appconstant from "../../themes/appconstant";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { updateAppVersion, versionDetailAction } from "../../redux/Action/Auth";

const VersionManagement = () => {
  const dispatch = useDispatch();
  const { versionDetails } = useSelector((state) => state.versionManagementReducer)

  useLayoutEffect(() => {
    dispatch(versionDetailAction());
  }, [])

  useEffect(() => {
    if (versionDetails) {
      formik.setFieldValue("androidAppVersion", versionDetails?.androidVersion);
      formik.setFieldValue("iosAppVersion", versionDetails?.iosVersion);
    };
  }, [versionDetails])

  const initialValues = {
    androidAppVersion: "",
    iosAppVersion: ""
  }

  const validationSchema = Yup.object({
    androidAppVersion: Yup.string().required("Please enter android app version.")
      .matches(
        /^(\d+)\.(\d+)\.(\d+)$/,
        "Please enter valid android app version."
      ),
    iosAppVersion: Yup.string().required("Please enter ios app version.")
      .matches(
        /^(\d+)\.(\d+)\.(\d+)$/,
        "Please enter valid ios app version."
      ),

  })

  const onSubmit = (values) => {
    dispatch(updateAppVersion({
      iosVersion: values.iosAppVersion,
      androidVersion: values.androidAppVersion
    }))
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const handleNumberInputChange = (value, name, length) => {
    let data = value;
    data.target.value = data.target.value.slice(0, length);
    return formik.setFieldValue(name, data.target.value.trim())
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.VersionManagement}</Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.VersionManagement}
            </h6>
          </div>

          <form className="site-layout-background" onSubmit={formik.handleSubmit} style={{ padding: 24, minHeight: 360 }}>
            <div className="content-e">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
              </div>

              <div className="wrapper_line">
                <div className="form-group">
                  <label>{appconstant.androidAppVersion}</label>
                  <input
                    className='form-control'
                    type='text'
                    placeholder="Android App Version" name='androidAppVersion'
                    // onChange={formik.handleChange}
                    onChange={(e) => {
                      handleNumberInputChange(
                        e,
                        "androidAppVersion",
                      );
                    }}
                    onBlur={formik.handleBlur}
                    // onChange={(e) => {
                    //     if (e.target.value.startsWith(' ') || ! /^[A-Za-z0-9 ]*$/.test(e.target.value)) e.preventDefault()
                    //     else formik.handleChange(e)
                    // }}
                    value={formik.values.androidAppVersion}
                  />

                </div>
                {formik.touched.androidAppVersion && formik.errors.androidAppVersion && (
                  <div className="form-validation">{formik.errors.androidAppVersion}</div>
                )}
                <div className="form-group">
                  <label>{appconstant.iosAppVersion}</label>
                  <input className='form-control' type='text'
                    placeholder="iOS App Version" name='iosAppVersion'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.iosAppVersion}
                  />

                </div>
                {formik.touched.iosAppVersion && formik.errors.iosAppVersion && (
                  <div className="form-validation">{formik.errors.iosAppVersion}</div>
                )}

                <div className="center-align">
                  <button type="submit" className="button-list">{appconstant.update}</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default VersionManagement;