import { all, fork } from "redux-saga/effects";
import rootAuthSaga from "./Auth";
import NotificationSaga from "./notification";
import UserManagementSaga from "./userManagementSaga";
import ShopManagementSaga from "./shopManagementSaga";
import TournamentManagementSaga from "./tournamentManagementSaga";
import GameManagementSaga from "./gameManagmentSaga";
import VersionManagementSaga from "./versionManagement";

export default function* rootSaga() {
  yield all([
    fork(rootAuthSaga),
    fork(NotificationSaga),
    fork(UserManagementSaga),
    fork(ShopManagementSaga),
    fork(TournamentManagementSaga),
    fork(GameManagementSaga),
    fork(VersionManagementSaga),
  ]);
}
