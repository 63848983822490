import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal, Pagination } from "antd";
import appconstant from "../../themes/appconstant";
import { useDispatch, useSelector } from "react-redux";
import { tournamentList, tournamentBlock } from "../../redux/Action/Auth";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { removeEmojisForSearch } from "../../common/validation";

const TournamentManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [payload, setPayload] = useState({
    search: searchParams.get("search") || "",
    offset: Number(searchParams.get("offset")) || 0,
    limit: Number(searchParams.get("limit")) || 10,
    sort: searchParams.get("sort") || null,
    order: Number(searchParams.get("order")) || null,
  });
  const [searchValue, setSearchValue] = useState(payload.search);

  const tournamentListData = useSelector(
    (x) => x.tournamentReducer.tournamentList
  );
  const loading = useSelector((state) => state.tournamentReducer.loading);

  useEffect(() => {
    const newParams = new URLSearchParams(payload);
    setSearchParams(newParams); // update the URL when payload changes
  }, [payload]);

  const handleSearch = (e) => {
    const searchData = removeEmojisForSearch(e?.target?.value);
    if (!searchData?.startsWith(" ")) {
      const newParams = new URLSearchParams({
        ...payload,
        search: e?.target?.value,
        offset: 0,
      });
      setSearchParams(newParams);
      setSearchValue(searchData);
    }
  };

  const handleChange = (col, page) => {
    console.log(col, page, "working");
    const tempPayload = { ...payload };

    if (col && col.columnKey) {
      tempPayload["sort"] = col?.columnKey || payload?.sort;
      tempPayload["order"] =
        col?.order === "ascend"
          ? 1
          : col?.order === "descend"
          ? -1
          : payload?.order;
    } else if (page) {
      tempPayload["offset"] = (page - 1) * 10;
    }

    setPayload({ ...tempPayload, search: searchValue });
    const newParams = new URLSearchParams({
      ...tempPayload,
      search: searchValue,
    });
    setSearchParams(newParams); // update query parameters in the URL
    dispatch(tournamentList({ ...tempPayload, search: searchValue })); // dispatch to get game list
  };

  const disableModel = (record) => {
    Modal.confirm({
      title: record?.isBlock ? appconstant.unBlock : appconstant.block,
      content: record?.isBlock
        ? appconstant.unblockWarning
        : appconstant.blockWarning,
      okText: "Yes",
      centered: true,
      cancelText: "No",
      onOk() {
        dispatch(tournamentBlock({ id: record._id }));
      },
      onCancel() {},
      maskClosable: true,
      className: "new-button",
    });
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      render: (value, item, index) => {
        return Number(searchParams.get("offset")) + index + 1;
      },
    },
    {
      title: "Tournament Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      title: "Tournament Start Date & Time",
      dataIndex: "dateTime",
      key: "dateTime",
      sorter: true,
      render: (value, item) => (
        <div>
          {item.dateTime
            ? moment(item.dateTime).format("DD/MM/YYYY HH:mm:ss")
            : "N/A"}
        </div>
      ),
    },
    {
      title: "Total Number Of Players",
      dataIndex: "totalPlayers",
      key: "totalPlayers",
      sorter: true,
    },
    {
      title: "Registration Amount",
      dataIndex: "registerCoins",
      key: "registerCoins",
      sorter: true,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (value, item) => (
        <div>
          <button
            className="view-btn"
            type="button"
            onClick={() =>
              navigate("/viewtournament", { state: { id: item._id } })
            }
          >
            {appconstant.view}
          </button>
          <button
            className={
              item?.isStart ? "edit-btn cursor-not-allowed" : "edit-btn"
            }
            disabled={item?.isStart}
            onClick={() =>
              navigate("/edittournamentdetail", {
                state: { ...item, id: item._id },
              })
            }
          >
            {appconstant.edit}
          </button>
          <button
            className={
              item?.isStart ? "block-btn cursor-not-allowed" : "block-btn"
            }
            disabled={item?.isStart}
            onClick={() => disableModel(item)}
          >
            {!item.isBlock ? appconstant.block : appconstant.unBlock}
          </button>
          <Link to="/tournamentlogs" state={item._id}>
            <button className="tour-btn" type="button">
              Tournament Logs
            </button>
          </Link>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (searchValue?.length <= 50) {
      dispatch(tournamentList({ ...payload, search: searchValue, offset: 0 }));
    }
  }, [searchValue, dispatch]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const initialPayload = {
      offset: Number(queryParams.get("offset")) || 0,
      limit: Number(queryParams.get("limit")) || 10,
      sort: queryParams.get("sort") || null,
      order: Number(queryParams.get("order")) || null,
      search: queryParams.get("search") || "",
    };
    setPayload(initialPayload); // set the payload from query params
    setSearchValue(initialPayload.search); // set search value from query params
    dispatch(tournamentList(initialPayload)); // fetch data based on initial payload
  }, [dispatch]);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.tournamentManagement}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.tournamentManagement}
            </h6>
            <input
              type="text"
              className="iocn-search  search-bar"
              placeholder="Search"
              value={searchValue}
              onChange={handleSearch}
            />
          </div>
          <div>
            <Link to="/addtournamentDetail">
              <button type="button" className="button-list">
                {appconstant.create}
              </button>
            </Link>
          </div>
          <Table
            dataSource={tournamentListData?.list?.map((item, index) => ({
              ...item,
              key: index,
            }))}
            columns={columns}
            onChange={(x, y, col) => handleChange(col)}
            showSorterTooltip={false}
            pagination={false}
            // pagination={{
            //   total: tournamentListData?.pagination?.totalCount || 0,
            //   current: currentPage,
            //   showSizeChanger: false,
            // }}

            // pagination={{
            //   current: tournamentListData?.pagination?.currentPage,
            //   pageSize: tournamentListData?.pagination?.limit,
            //   total: tournamentListData?.pagination?.totalCount,
            //   showSizeChanger: false,
            //   onChange: (page) => handleChange(null, page),
            // }}
            loading={loading}
          />
          <Pagination
            showSizeChanger={false}
            showTitle={false}
            className="pagination-wrapper"
            total={tournamentListData?.pagination?.totalCount}
            current={tournamentListData?.pagination?.currentPage}
            onChange={(page) => handleChange(null, page)}
          />
        </div>
      </div>
    </div>
  );
};

export default TournamentManagement;
