import { reduxConstant } from "../../constants/appconstant"

const initialState = {
    isLoading: false,
    userList: [],
    pagination: {},
    userDetail: {},
    economyList : {}
}

export const userManagementReducer = (state = initialState, action) => {
    switch (action.type) {

        //user list
        case reduxConstant.API_User_List_LOAD: return { ...state, isLoading: true };
        case reduxConstant.API_USER_LIST_SUCCESS: return { ...state, isLoading: false, userList: action?.result?.data, pagination: action?.result?.pagination };

        // economy logs 

        case reduxConstant.ECONOMY_LOG_LOAD :  return { ...state, isLoading: true };

        case reduxConstant.ECONOMY_LOG_SUCCESS: return { ...state, isLoading: false, economyList: action?.result?.data, pagination: action?.result?.pagination };

        case reduxConstant.API_BLOCK_USER_LOAD: return { ...state, isLoading: true };
        case reduxConstant.API_BLOCK_USER_SUCCESS: return {
            ...state,
            isLoading: false,
            userList: {
                ...state?.userList,
                userList: state?.userList?.userList?.map(i => {
                    if (i._id === action.result) {
                        i.isBlock = !i.isBlock
                    }
                    return i;
                })
            }
        };

        case reduxConstant.API_VIEW_USER_LOAD: return { ...state, isLoading: true, userDetail: {} };
        case reduxConstant.API_VIEW_USER_SUCCESS: return { ...state, isLoading: false, userDetail: action?.result?.data };

        //Fail
        case reduxConstant.API_USER_MANAGEMENT_FAIL: return { ...state, isLoading: false };

        //default
        default: return { ...state };
    }
}