import React, { useEffect } from "react"
import images from '../../themes/appImage'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import { Forgot } from '../../redux/Action/Auth'
import { useDispatch } from 'react-redux'
import * as Yup from "yup";

const ForgotPassword = () => {
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .required("Please enter email address.")
                .matches(
                    /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-z]{2,10})+$/,
                    "Please enter valid email address."
                )
                .max(255),
        }),

        onSubmit: (values) => {
            dispatch(Forgot(values))
        }
    })
    useEffect(() => {
        document.title = 'Albastini';
        window.scrollTo(0, 0)

    }, [])

    return (
        <div className="image-bg">
            <div className="page-header">


                <div className="main-div">
                    <div className="login-form mt-top">
                        <div className="back">
                            <Link to="/login"> <button type="submit" className=" btn2  "> <FontAwesomeIcon className="icon-angle-left" icon={faAngleLeft} /></button></Link>
                        </div>
                        <div> <img alt="" src={images.login} /></div>
                        <h4>Forgot Password </h4>
                        <form noValidate onSubmit={formik.handleSubmit}>
                            <div className="user">
                                <input type="text"
                                    placeholder="Email Address"
                                    name='email'
                                    value={(formik.values.email).trim()}
                                    onChange={(e) => {
                                        let data = e.target.value.trim();
                                        formik.setFieldValue("email", data);
                                    }}
                                />
                            </div>
                            {formik.errors.email ? (
                                <div className="error">{formik.errors.email}</div>
                            ) : null}
                            <div className="login-link">
                                <button type="submit" >Send</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>

    )
}
export default ForgotPassword;
