import React, { useEffect, useState } from "react";
import { Table, Breadcrumb, Input, Modal } from "antd";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import images from "../../themes/appImage";
import { gamesLogsAction } from "../../redux/Action/Auth";
import { useDispatch, useSelector } from "react-redux";

const Gamelog = () => {
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModal2Visible, setIsModalVisible2] = useState(false);
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalContent, setModalContent] = useState([]);

  const { gameList } = useSelector((state) => state.gameManagementReducer);

  console.log(modalContent, "this is modal");

  const deckImages = [
    /* your images */
  ];
  const pendingImages = [
    /* your images */
  ];

  useEffect(() => {
    dispatch(
      gamesLogsAction({
        gameId: location.state,
      })
    );
  }, [dispatch, location.state]);

  const transformGameList = (gameList) => {
    if (gameList && gameList.length > 0) {
      return gameList
        ?.map((game, index) => {
          if (game.action === "cardDistribution") {
            return {
              key: index + 1,
              step: `${index + 1}`,
              event: "Card Distribution",
              details: "Cards distributed to each user; Updated deck",
              player1Cards: game.player1.cards,
              player2Cards: game.player2.cards,
              player1: game.player1.player,
              player2: game.player2.player,
              action: game.action,
            };
          } else if (game.action === "bid") {
            return {
              key: index + 1,
              step: `${index + 1}`,
              event: `Bid Trick ${index} (${game?.bidStatus ? "Yes" : "No"})`,
              details: game?.cardThrown
                ? `Card played by ${game.playedBy} ${
                    game?.cardThrown ? "" : "N/A"
                  }`
                : "-",
              cardThrown: game.cardThrown,
              cardThrownbyPlayer: game.cardThrownbyPlayer,
              action: game.action,
              pendingCard: game.pendingCard,
              updatedDeck: game.updatedDeck,
            };
          } else if (game.action === "bidResult") {
            return {
              key: index + 1,
              step: `${index + 1}`,
              event: "Bid Result (Winner Declared)",
              details: game.bidWinner
                ? `Winner ${game.bidWinner} Card from the deck; Dominant suite: ${game.dominatSuit}`
                : "No Winner",
              dominatSuit: game.dominatSuit,
              action: game.action,
              randomCardFromDeck: game?.randomCardFromDeck,
            };
          } else if (game.action === "cardPlayed") {
            return {
              key: index + 1,
              step: `${index + 1}`,
              event: "Card Played",
              details: `Card played by ${game.playedBy},  ${game.cardThrown.name} of ${game.cardThrown.suit}`,
              cardThrown: game.cardThrown,
              action: game.action,
              pendingCard: game.pendingCard,
              updatedDeck: game.updatedDeck,
            };
          } else if (game.action === "roundWinnerDeclare") {
            return {
              key: index + 1,
              step: `${index + 1}`,
              event: "Round Winner Declared",
              details: `${game?.roundWinner}`,
              action: game.action,
            };
          } else if (game.action === "selfExit") {
            return {
              key: index + 1,
              step: `${index + 1}`,
              event: "Self Exit",
              details: `Player ${game.userName} exited the game`,
              action: game.action,
            };
          } else if (game.action === "winnerDeclare") {
            return {
              key: index + 1,
              step: `${index + 1}`,
              event: "Winner Declared",
              details: `The winner of the round is ${game.userName}`,
              action: game.action,
            };
          } else if (game.action === "randomDeckCards") {
            return {
              key: index + 1,
              step: `${index + 1}`,
              event: "Card given by dealer",
              details: ``,
              action: game.action,
              randomDeckCards: game.randomDeckCards,
            };
          }
          return null;
        })
        .filter((item) => item !== null);
    }
  };

  const transformedLogs = transformGameList(gameList);

  const handleOpenModal = (player, title) => {
    console.log("this is my title", title);
    setModalContent(player);
    setModalTitle(title);
    setIsModalVisible(true);
  };

  const handleOpenModal2 = (player) => {
    setModalContent(player);

    setIsModalVisible2(true);
  };

  const handleOpenModal3 = (player) => {
    setModalContent(player);

    setIsModalVisible3(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setModalContent([]);
  };

  const handleCloseModal2 = () => {
    setIsModalVisible2(false);
    setModalContent([]);
  };

  const handleCloseModal3 = () => {
    setIsModalVisible3(false);
    setModalContent([]);
  };

  const renderCardImages = (cards) => {
    return cards.map((card, index) => (
      <img
        key={index}
        src={images[card.value]} // Assuming images are named as per card value
        alt={`Card ${index}`}
        style={{
          height: 60,
          width: 60,
          margin: 5,
          objectFit: "contain",
          borderRadius: 5,
        }}
      />
    ));
  };

  const columns = [
    {
      title: "Step",
      dataIndex: "step",
      key: "step",
    },
    {
      title: "Event",
      dataIndex: "event",
      key: "event",
      align: "center",
      render: (e) => (
        <div>{e == "Round Winner Declared" ? "Trick Winner Declared" : e}</div>
      ),
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      render: (text, record) => {
        if (record.cardThrown) {
          return (
            <div>
              {text}
              <img
                src={images[record.cardThrown.value]} // Use card value to get the image
                alt="Card"
                style={{
                  height: 40,
                  width: 30,
                  marginLeft: 10,
                  borderRadius: 2,
                }}
              />
            </div>
          );
        }
        if (record.dominatSuit) {
          return (
            <div>
              {text}
              {record.randomCardFromDeck && (
                <img
                  src={images[record.randomCardFromDeck]} // Use card value to get the image
                  alt="Card"
                  style={{
                    height: 40,
                    width: 30,
                    marginLeft: 10,
                    borderRadius: 2,
                  }}
                />
              )}
              <img
                src={images[record.dominatSuit]} // Use card value to get the image
                alt="Card"
                style={{
                  height: 40,
                  width: 30,
                  marginLeft: 10,
                  borderRadius: 2,
                  objectFit: "contain  ",
                }}
              />
            </div>
          );
        }
        if (record.action === "randomDeckCards") {
          return (
            <div>
              {`Card Recived by ${record?.randomDeckCards[0]?.player}`}
              <img
                src={images[record?.randomDeckCards[0]?.value]} // Use card value to get the image
                alt="Card"
                style={{
                  height: 40,
                  width: 30,
                  marginLeft: 10,
                  borderRadius: 2,
                }}
              />

              {`Card Recived by ${record?.randomDeckCards[1]?.player}`}
              <img
                src={images[record?.randomDeckCards[1]?.value]} // Use card value to get the image
                alt="Card"
                style={{
                  height: 40,
                  width: 30,
                  marginLeft: 10,
                  borderRadius: 2,
                  objectFit: "contain  ",
                }}
              />
            </div>
          );
        }
        console.log(text, "askldkalsd");
        return text;
      },
    },
    {
      title: "Actions",
      dataIndex: "Posted",
      key: "Posted",
      render: (value, item) => {
        if (item.action === "cardDistribution") {
          console.log(item, "this is item");
          return (
            <div>
              <button
                className="add-coins"
                type="button"
                onClick={() => handleOpenModal(item.player1Cards, item.player1)}
              >
                Player 1
              </button>
              <button
                className="add-coins"
                type="button"
                onClick={() => handleOpenModal(item.player2Cards, item.player2)}
              >
                Player 2
              </button>
            </div>
          );
        } else if (item.action == "cardPlayed" || item.action == "bid") {
          console.log(item, "thsdsad");
          return (
            <div>
              <button
                className="tour-btn"
                type="button"
                onClick={() => handleOpenModal2(item.updatedDeck)}
              >
                Deck Details
              </button>
              <button
                className="tour-btn"
                type="button"
                onClick={() => handleOpenModal3(item.pendingCard)}
              >
                Pending Cards
              </button>
            </div>
          );
        } else {
          return null;
        }
      },
    },
  ];

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/gamemanagement">{appconstant.GameManagement}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Game Logs</Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">Game Logs</h6>
            {/* <Input 
              placeholder="Search" 
              className="search-bar" 
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            /> */}
          </div>

          <Table
            columns={columns}
            dataSource={transformedLogs}
            pagination={false}
          />

          <Modal
            title={`${modalTitle} Cards`}
            visible={isModalVisible}
            onCancel={handleCloseModal}
            footer={null}
            width={800}
          >
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {renderCardImages(modalContent)}
            </div>
          </Modal>

          <Modal
            title={`Pending Card`}
            visible={isModalVisible3}
            onCancel={handleCloseModal3}
            footer={null}
            width={800}
          >
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {renderCardImages(modalContent)}
            </div>
          </Modal>

          <Modal
            title="Deck Details"
            visible={isModal2Visible}
            onCancel={handleCloseModal2}
            footer={null}
            width={800}
          >
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {modalContent.map((item, index) => (
                <img
                  key={index}
                  src={images[item]}
                  alt={`Card ${index}`}
                  style={{
                    height: 60,
                    width: 60,
                    margin: 5,
                    objectFit: "contain",
                    borderRadius: 5,
                  }}
                />
              ))}
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Gamelog;
