import './App.css';
import '../src/css/style.css'
import '../src/css/responsive.css'


import { useNavigate } from 'react-router-dom';

import { naviagetFn } from '../src/utils/navigator'

import 'antd/dist/antd.css'
import RoutesNew from './Route/Routes';



function App() {
  const navigate = useNavigate()
  naviagetFn(navigate)
  return (
    <RoutesNew />
  );
}

export default App;
