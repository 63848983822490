import { reduxConstant } from "../../constants/appconstant"
import { put, call, takeLatest } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { addTournament, tournamentList, tournamentBlock, tournamentDetails, tournamentEdit, tournamentLogsApi } from "../Api/index";
import { navigate } from '../../utils/navigator'


function* addTournamentSaga(action) {

    const result = yield call(addTournament, action.data);

    if (result.status === 0) {

        yield put({ type: reduxConstant.TOURNAMENT_ADD_SUCCESS, });

        toast.success(result.result.message, { toastId: 1 });
        navigate('/tournamentmanagement');
    }
    else {

        toast.error(result.result.message);
        yield put({ type: reduxConstant.TOURNAMENT_MANAGEMENT_FAILED, });
    }
}

function* tournamentListSaga(action) {

    const result = yield call(tournamentList, action.data);

    if (result.status === 0) yield put({ type: reduxConstant.TOURNAMENT_LIST_SUCCESS, result: result?.result?.data });
    else {

        toast.error(result.result.message);
        yield put({ type: reduxConstant.TOURNAMENT_MANAGEMENT_FAILED, });
    }
}


function* tournamentDetailsSaga(action) {

    const result = yield call(tournamentDetails, action.data);

    if (result.status === 0) yield put({ type: reduxConstant.TOURNAMENT_DETAILS_SUCCESS, result: result?.result?.data });
    else {

        toast.error(result.result.message);
        yield put({ type: reduxConstant.TOURNAMENT_MANAGEMENT_FAILED, });
    }
}


function* tournamentBlockSaga(action) {

    const result = yield call(tournamentBlock, action.data);

    if (result.status === 0) {

        toast.success(result.result.message, { toastId: 1 });
        yield put({ type: reduxConstant.TOURNAMENT_BLOCK_SUCCESS, data: action.data });
    }
    else {

        toast.error(result.result.message);
        yield put({ type: reduxConstant.TOURNAMENT_MANAGEMENT_FAILED, });
    }
}

function* tournamentEditSaga(action) {

    const result = yield call(tournamentEdit, action.data);
    

    if (result.status === 0) {

        toast.success(result.result.message, { toastId: 1 });
        yield put({ type: reduxConstant.TOURNAMENT_EDIT_SUCCESS });
        action.navigate('/tournamentmanagement')
    }
    else {

        toast.error(result.result.message);
        yield put({ type: reduxConstant.TOURNAMENT_MANAGEMENT_FAILED, });
    }
}

function* tournamentlogSaga(action) {
    try {
        const result = yield call(tournamentLogsApi, action.data);
        

        if (result.status == 0) {
            console.log(result.result.data.gameList  , "this is result")
            yield put({ type: reduxConstant.TOURNAMENT_LOG_SUCCESS, result: result.result?.data?.gameList });
        } else {
            const errorMessage = result?.result?.message || "An error occurred";
            toast.error(errorMessage);
            yield put({ type: reduxConstant.TOURNAMENT_LOG_FAIL });
        }
    } catch (error) {
        console.error("Error in tournamentlogSaga:", error);
        toast.error("An unexpected error occurred");
        yield put({ type: reduxConstant.TOURNAMENT_LOG_FAIL });
    }
}

export default function* NotificationSaga() {

    yield takeLatest(reduxConstant.TOURNAMENT_ADD_LOAD, addTournamentSaga);
    yield takeLatest(reduxConstant.TOURNAMENT_LIST_LOAD, tournamentListSaga);
    yield takeLatest(reduxConstant.TOURNAMENT_DETAILS_LOAD, tournamentDetailsSaga);
    yield takeLatest(reduxConstant.TOURNAMENT_BLOCK_LOAD, tournamentBlockSaga);
    yield takeLatest(reduxConstant.TOURNAMENT_EDIT_LOAD, tournamentEditSaga);
    yield takeLatest(reduxConstant.TOURNAMENT_LOG_LOAD , tournamentlogSaga);

}
