import React from "react"

const Textfiled = (props) => {
    const {
        type,
        className,
        placeholder,
        disabled,
        defaultValue,
        name,
        value,
        onChange,
        maxLength
    } = props

    return (
        <div className="input_fields">
            <input
                type={type}
                placeholder={placeholder}
                defaultValue={defaultValue}
                className={className}
                disabled={disabled}
                name={name}
                value={value}
                onChange={onChange}
                maxLength={maxLength}
            />
        </div>
    )
}

export const Activity = (props) => {
    const handleViewClick = () => {
      if (props.onViewClick) {
        props.onViewClick(props.id);
      }
    };
  
    return (
      <li>
        <span className='bullet'></span>
        <div className="activity-data">
          <p>{props.date}</p>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "10px" }}>
            <div style={{ marginTop: "9px" }}>
              <p className='data'>{props.activity}</p>
            </div>
            <div>
              <button style={{color:"white" , backgroundColor : "skyblue" , border : "1px solid skyblue"}} onClick={handleViewClick}>View</button>
            </div>
          </div>
        </div>
      </li>
    );
  };

export const Activity2 = (props) => {
    return (
        <li>
            <span className='bullet'></span>
            <div className="activity-data">
                <p>{props.date}</p>
               <p className='data'>{props.activity}</p>
             
                
            </div>
        </li>
    )
};

export default Textfiled;