import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import appconstant from "../../themes/appconstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "antd";
import images from "../../themes/appImage";
import { reduxConstant } from "../../constants/appconstant";
import { useDispatch, useSelector } from "react-redux";
import {
  viewUserAction,
  editUserAction,
  viewShopAction,
  shopEditAction,
} from "../../redux/Action/Auth";
import { useFormik } from "formik";
import * as Yup from "yup";

const EditTheme = () => {
  useEffect(() => {
    document.title = "Albastini";
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userDetail = useSelector((x) => x?.shopManagementReducer.itemDetail);

  const location = useLocation();

  const [image, setImage] = useState(null);
  const [payloadImage, setPayloadImage] = useState(null);
  const [imageError, setImageError] = useState("");
  const [imageURL, setImageURL] = useState(null);

  useEffect(() => {
    dispatch(viewShopAction(location?.state));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [initialValues, setInitialValues] = useState({
    nameEn: "",
    nameSw: "",
    cost: "",
    image: ""
  });

  const imageHandle = (e) => {
    if (e.target.files[0]) {

      console.log(e, "this is my e data");
      setImage(URL.createObjectURL(e.target.files[0]));
      setPayloadImage(e.target.files[0]);
    }
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if(file.type == "image/png"){
      handleImageChange(file)
        .then((e) => {
          setImageError("");
          setImage(URL.createObjectURL(file));
          setPayloadImage(file);
        })
        .catch((err) => {
          setImageError("Image height must be less then 3288 px and width must be less then 1720 px.");
        });
      }
      else{
        setImageError("Image must be in PNG format.");

      }
    }
  };

  const handleImageChange = (event) => {
    var _URL = window.URL || window.webkitURL;
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = _URL.createObjectURL(event);
      image.onload = () => {
        console.log("DATA", image.height);
       (image.width <= 1720 && image.height <= 3288)
          ? resolve("true")
          : reject("false");
      };
    });
  };


  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      nameEn: Yup.string()
        .required("Please enter theme name (English).")
        .min(3, "Name must contain at least 3 characters.")
        .max(25),
      nameSw: Yup.string()
        .required("Please enter theme name (Swahili).")
        .min(3, "Name must contain at least 3 characters.")
        .max(25),
        cost: Yup.number()
        .required("Please enter price.")
        .min(0, "Price must be a numeric value.")
        .typeError("Price must be a numeric value."),
    }),
    onSubmit: (values) => {
      let payload = new FormData();

      payload.append("id", location?.state);
      payload.append("nameEn", values.nameEn);
      payload.append("nameSw", values.nameSw);
      payload.append("cost", parseInt(values.cost));
      payload.append("type", "skins");
      payload.append("profileImage", payloadImage);

      dispatch(shopEditAction(payload, navigate));
    },
  });
  useEffect(() => {
    setInitialValues({
      nameEn: userDetail?.nameEn ? userDetail?.nameEn : "",
      nameSw: userDetail?.nameSw ? userDetail?.nameSw : "",
      cost: userDetail?.cost ? userDetail?.cost : "",
    });
  }, [userDetail]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/shopmanagement"> {appconstant.ShopManagement} </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.editTheme}</Breadcrumb.Item>
      </Breadcrumb>
      <form
        className="site-layout-background"
        onSubmit={formik.handleSubmit}
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.editTheme}
            </h6>
            <input
              type="number"
              className="search-bar"
              placeholder="Search"
              style={{ visibility: "hidden" }}
            />
          </div>

          <div className="datacenter">
            <label htmlFor="file-input" className="user-detail-image-label">
              <div className="datacenter-icon">
                <img alt="" src={images.pluss} title="Click to edit image" />
              </div>
              <img
                alt=""
                src={
                  !image
                    ? userDetail?.imageUrl
                      ? `${reduxConstant.IMAGE_URL}/${userDetail?.imageUrl}`
                      : images.dummyUser
                    : image
                }
                style={{
                  width: "105px",
                  height: "100px",
                  borderRadius: "100%",
                }}
              />
            </label>
            <input
              id="file-input"
              type="file"
              onChange={handleFileChange}
              className="hidden"
              accept="image/png, image/jpg, image/jpeg"
            />
          </div>
          {imageError && <div style={{ marginTop: 5, marginLeft: 15 }} className="form-validation">{imageError}</div>}
          <div className="wrapper_line">
            <div className="form-group">
              <label>{appconstant.ThemeName}</label>
              <input
                className="form-control"
                type="text"
                placeholder="Theme Name"
                name="nameEn"
                onChange={(e) => {
                  if (
                    e.target.value.startsWith(" ") ||
                    !/^[A-Za-z0-9 ]*$/.test(e.target.value)
                  )
                    e.preventDefault();
                  else formik.handleChange(e);
                }}
                value={formik.values.nameEn}
              ></input>
            </div>
            {formik.errors.nameEn && <div className="form-validation">{formik.errors.nameEn}</div>}

            <div className="form-group">
              <label>{appconstant.ThemeNameSwahili}</label>
              <input
                className="form-control"
                type="text"
                placeholder="Theme Name"
                name="nameSw"
                onChange={(e) => {
                  if (
                    e.target.value.startsWith(" ") ||
                    !/^[A-Za-z0-9 ]*$/.test(e.target.value)
                  )
                    e.preventDefault();
                  else formik.handleChange(e);
                }}
                value={formik.values.nameSw}
              ></input>
            </div>
            {formik.errors.nameSw && <div className="form-validation">{formik.errors.nameSw}</div>}

            <div className="form-group">
              <label>{appconstant.Price}</label>
              <input
                className="form-control"
                type="text"
                placeholder="price"
                name="cost"
                maxLength={3}
                onChange={(e) => {
                  if (
                    e.target.value.startsWith(" ") ||
                    !/^[A-Za-z0-9 ]*$/.test(e.target.value)
                  )
                    e.preventDefault();
                  else formik.handleChange(e);
                }}
                value={formik.values.cost}
              ></input>
            </div>
            {formik.errors.cost && <div className="form-validation">{formik.errors.cost}</div>}

            <div className="center-align">
              <button type="submit" className="button-list">
                {appconstant.update}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default EditTheme;
