import { msgConstant, reduxConstant } from "../../constants/appconstant";
import { put, call, takeLatest } from "@redux-saga/core/effects";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  gameLogsApi,
  getErrorList,
  getGameList,
  viewErrorLogsApi,
} from "../Api/index";
import { navigate } from "../../utils/navigator";

function* getGameListSaga(action) {
  try {
    const response = yield call(getGameList, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      yield put({
        type: reduxConstant.API_GET_GAME_LIST_SUCCESS,
        result: response?.result,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_GET_GAME_LIST_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_GET_GAME_LIST_FAIL });
  }
}

function* errorLogSaga(action) {
  try {
    const response = yield call(getErrorList, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      yield put({
        type: reduxConstant.ERROR_LIST_SUCCESS,
        result: response?.result,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.ERROR_LIST_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.ERROR_LIST_FAIL });
  }
}

function* gameLogsSaga(action) {
  try {
    const response = yield call(gameLogsApi, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      yield put({
        type: reduxConstant.GAME_LOGS_SUCCESS,
        result: response?.result,
      });
    } else {
      toast.error(response.result.message);
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
  }
}

function* viewErrorSaga(action) {
  try {
    const response = yield call(viewErrorLogsApi, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      yield put({
        type: reduxConstant.VIEW_ERROR_SUCCESS,
        result: response?.result,
      });
    } else {
      toast.error(response.result.message);
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
  }
}

export default function* GameManagementSaga() {
  yield takeLatest(reduxConstant.API_GET_GAME_LIST_LOAD, getGameListSaga);
  yield takeLatest(reduxConstant.GAME_LOGS_LOAD, gameLogsSaga);
  yield takeLatest(reduxConstant.ERROR_LIST_LOAD, errorLogSaga);
  yield takeLatest(reduxConstant.VIEW_ERROR_LOAD, viewErrorSaga);
}
