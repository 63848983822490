import { reduxConstant } from "../../constants/appconstant";

const initialState = {
  isLoading: false,
  gameList: [],
  pagination: {},
  errorList: [],
  errorData: [],
  gameLoad: false,
};

export const gameManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case reduxConstant.API_GET_GAME_LIST_LOAD:
      return { ...state, isLoading: true, gameLoad: true };
    case reduxConstant.API_GET_GAME_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        gameList: action?.result?.data,
        pagination: action?.result?.pagination,
        gameLoad: false,
      };

    case reduxConstant.VIEW_ERROR_LOAD:
      return {
        ...state,
        isLoading: true,
      };

    case reduxConstant.VIEW_ERROR_SUCCESS:
      return {
        ...state,
        errorData: action?.result?.data,
        isLoading: false,
      };
    case reduxConstant.ERROR_LIST_LOAD:
      return { ...state, isLoading: true, gameLoad: true };
    case reduxConstant.ERROR_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorList: action?.result?.data,
        pagination: action?.result?.pagination,
        gameLoad: false,
      };

    case reduxConstant.GAME_LOGS_LOAD:
      return { ...state, isLoading: true };

    case reduxConstant.GAME_LOGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        gameList: action?.result?.data.gameList,
      };

    //default
    default:
      return { ...state };
  }
};
