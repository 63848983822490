import { msgConstant, reduxConstant } from "../../constants/appconstant";
import { put, call, takeLatest } from "@redux-saga/core/effects";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addShopDataApi,
  getShopDataApi,
  shopEditApi,
  shopListApi,
  viewShopApi,
} from "../Api/index";
import { navigate } from "../../utils/navigator";

function* shopListSaga(action) {
  try {
    const response = yield call(shopListApi, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      yield put({
        type: reduxConstant.API_SHOP_LIST_SUCCESS,
        result: response?.result,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_SHOP_MANAGEMENT_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_SHOP_MANAGEMENT_FAIL });
  }
}

function* viewShopSaga(action) {
  try {
    const response = yield call(viewShopApi, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      yield put({
        type: reduxConstant.API_VIEW_SHOP_SUCCESS,
        result: response.result,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_SHOP_MANAGEMENT_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_SHOP_MANAGEMENT_FAIL });
  }
}

function* getShopDataSaga(action) {
  try {
    const response = yield call(getShopDataApi, action?.data);
    if (response?.status >= 0 && response?.status < 1) {
      yield put({
        type: reduxConstant.GET_SHOP_DATA_SUCCESS,
        result: response.result,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.GET_SHOP_DATA_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.GET_SHOP_DATA_FAIL });
  }
}
function* addShopDataSaga(action) {
  try {
    const response = yield call(addShopDataApi, action?.data);
    if (response?.status >= 0 && response?.status < 1) {
      toast.success(response.result.message);
      yield put({
        type: reduxConstant.ADD_SHOP_DATA_SUCCESS,
        result: response.result,
      });
      navigate("/shopmanagement");
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.ADD_SHOP_DATA_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.ADD_SHOP_DATA_FAIL });
  }
}
function* shopEditSaga(action) {
  try {
    const response = yield call(shopEditApi, action?.data);
    if (response?.status >= 0 && response?.status < 1) {
      toast.success(response.result.message);
      yield put({
        type: reduxConstant.API_SHOP_EDIT_SUCCESS,
        result: response.result,
      });
      navigate("/shopmanagement");
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.ADD_SHOP_DATA_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.ADD_SHOP_DATA_FAIL });
  }
}
function* addShopItemSaga(action) {
  try {
    const response = yield call(viewShopApi, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      yield put({
        type: reduxConstant.API_ADD_SHOP_ITEM_SUCCESS,
        result: response.result,
      });
      toast.success(response.result.message);
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_SHOP_MANAGEMENT_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_SHOP_MANAGEMENT_FAIL });
  }
}

export default function* ShopManagementSaga() {
  yield takeLatest(reduxConstant.API_SHOP_LIST_LOAD, shopListSaga);
  yield takeLatest(reduxConstant.API_VIEW_SHOP_LOAD, viewShopSaga);
  yield takeLatest(reduxConstant.API_ADD_SHOP_ITEM_LOAD, addShopItemSaga);
  yield takeLatest(reduxConstant.ADD_SHOP_DATA_LOAD, addShopDataSaga);
  yield takeLatest(reduxConstant.GET_SHOP_DATA_LOAD, getShopDataSaga);
  yield takeLatest(reduxConstant.API_SHOP_EDIT_LOAD, shopEditSaga);
}
