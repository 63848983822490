import React, { useEffect, useState } from "react";
import images from "../themes/appImage";

const HowToPlay = () => {

  const cards = [
    {
      images: [
        { src: images.image001, alt: "A card with a yellow circle and a yellow circle with a spade..." },
        { src: images.image002, alt: "A card with a red and white background" },
        { src: images.image003, alt: "A card with a yellow circle and a spade" },
        { src: images.image004, alt: "A card with a yellow circle and a blue and white background" }
      ],
      points: "11pts"
    },
    {
      images: [
        { src: images.image005, alt: "" },
        { src: images.image006, alt: "A card with a red and white background" },
        { src: images.image007, alt: "A card with a yellow circle and a black and white background" },
        { src: images.image008, alt: "A card with a number and a yellow circle" }
      ],
      points: "10pts"
    },
    {
      images: [
        { src: images.image009, alt: "A card with a green and white background" },
        { src: images.image010, alt: "A card with a red and white background" },
        { src: images.image011, alt: "A card with a yellow circle and a black and white background" },
        { src: images.image012, alt: "A card with a number and a yellow circle" }
      ],
      points: "4pts"
    },
    {
      images: [
        { src: images.image013, alt: "A card with a yellow circle and a yellow circle with a yellow..." },
        { src: images.image014, alt: "A card with a red and white background" },
        { src: images.image015, alt: "A card with a yellow circle and a black and white background" },
        { src: images.image016, alt: "A card with a yellow circle and a number on it" }
      ],
      points: "3pts"
    },
    {
      images: [
        { src: images.image017, alt: "A card with a green and white background" },
        { src: images.image018, alt: "A card with a red and white background" },
        { src: images.image019, alt: "A card with a yellow circle and a black and white background" },
        { src: images.image020, alt: "A card with a yellow circle and a blue and white background" }
      ],
      points: "2pts"
    },
    {
      images: [
        { src: images.image021, alt: "A card with a number and a yellow circle" },
        { src: images.image022, alt: "A card with a number and a red and white background" },
        { src: images.image023, alt: "A card with a yellow circle and a black and white background" },
        { src: images.image024, alt: "" }
      ],
      points: "0pts"
    },
    {
      images: [
        { src: images.image025, alt: "A card with a number and a circle" },
        { src: images.image026, alt: "A card with a number and a red and white background" },
        { src: images.image027, alt: "A card with a yellow circle and a black and white background" },
        { src: images.image028, alt: "" }
      ],
      points: "0pts"
    },
    {
      images: [
        { src: images.image029, alt: "A card with a number and a yellow circle" },
        { src: images.image030, alt: "A card with a number and a circle" },
        { src: images.image031, alt: "A card with a yellow circle and a black and white background" },
        { src: images.image032, alt: "" }
      ],
      points: "0pts"
    },
    {
      images: [
        { src: images.image033, alt: "A card with a number and a yellow circle" },
        { src: images.image034, alt: "A card with a number and a circle" },
        { src: images.image035, alt: "A card with a yellow circle and a black and white background" },
        { src: images.image036, alt: "" }
      ],
      points: "0pts"
    }
  ]

  return (
    <div style={{
      padding: 25,
      paddingBottom: 300,
    }}
    >
      <div>
        <p className="MsoNormal">
          <b><span style={{ background: 'yellow' }}>How to Play:</span></b>
        </p>
        <p className="MsoListParagraphCxSpFirst" style={{ textIndent: '-.25in' }}>
          <span style={{ fontFamily: 'Symbol' }}>®<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>
          <b><span style={{ color: '#C00000' }}>Overview:</span></b>
        </p>
        <p className="MsoListParagraphCxSpMiddle" style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          Albastini is a fun trick-taking card game that is originally from Tanzania, East Africa
        </p>
        <p className="MsoListParagraphCxSpMiddle" style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          The game is played with 32 cards (A, 7, K, J, Q, 6, 5, 4, & 3) which are worth 120 points total
        </p>
        <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: '-.25in' }}>
          <span style={{ fontFamily: 'Symbol' }}>®<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>
          <b><span style={{ color: '#C00000' }}>Objective:</span></b>
        </p>
        <p className="MsoListParagraphCxSpMiddle" style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          The goal is to accumulate the greatest number of points by outwitting your opponent
        </p>
        <p className="MsoListParagraphCxSpMiddle" style={{ textIndent: '-.25in' }}>
          <span style={{ fontFamily: 'Symbol' }}>®<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>
          <b><span style={{ color: '#C00000' }}>Card Values (in descending order):</span></b>
        </p>
        <table style={{ marginLeft: '10px', borderCollapse: 'collapse', border: 'none' }}>
          <tbody>
            {cards.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.images.map((image, colIndex) => (
                  <td key={colIndex} style={{ padding: '0in 5.4pt' }}>
                    <img width={61} height={90} src={image.src} alt={image.alt} />
                  </td>
                ))}
                <td style={{ padding: '0in 5.4pt' }}>
                  <p style={{ margin: 0 }}><b>Worth {row.points}</b></p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <h3><span style={{ color: '#C00000' }}>Step 1: Setup/Distribution</span></h3>
        <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
          <li>5 cards are given to each player</li>
          <li>Once the 5 cards have been distributed, each player will be prompted to bid</li>
        </ul>

        <h3><span style={{ color: '#C00000' }}>Step 2: Bidding</span></h3>
        <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
          <li>Bidding gives a player an opportunity to exchange a low value card for a higher value card (<u>Note</u>: Bidding is optional)</li>
          <li>The trade occurs if a player correctly guesses what the trump suit/color will be by playing a card that represents that suit/color</li>
          <li>Players can’t bid for the same suit/color</li>
          <li>Once players have placed a bid, the dealer will reveal a card that will represent the trump suit/color for the game</li>
          <li>The winner of the bid receives the revealed card as a reward in exchange for the original card they used for bidding</li>
          <li>The original card used for bidding is returned to all the players who did not win the bid</li>
          <li>The trump suit/color is displayed at the top left corner of the screen for reference throughout the game</li>
        </ul>

        <h3><span style={{ color: '#C00000' }}>Step 3: Gameplay</span></h3>
        <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
          <li>Only one card can be played per turn/round</li>
          <li>A new card is distributed from the deck after each round until the deck runs out</li>
          <li>Any card can be played during your turn (you don’t have to match the suit/color of the starting card)</li>
          <li>The game ends when all cards have been played</li>
        </ul>

        <h3><span style={{ color: '#C00000' }}>Step 4: How to Win</span></h3>
        <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
          <li>Win by either:</li>
          <ul style={{ listStyleType: 'none', paddingLeft: '20px' }}>
            <li><b>1.</b> Playing a higher value card within the same suit/color of the starting card</li>
            <li><u>Or</u></li>
            <li><b>2.</b> Playing any card within the trump suit/color</li>
          </ul>
          <li>Use the scenarios below as a reference guide for additional help</li>
        </ul>

        <h3><b><span style={{ backgroundColor: 'yellow' }}>Scenario 1:</span></b></h3>
        <table style={{ borderCollapse: 'collapse', border: 'none' }}>
          <tbody>
            <tr>
              <td colSpan="2" style={{ width: '191.6pt', border: 'solid windowtext 1.0pt', backgroundColor: 'black', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'right' }}><b>Trump Suit</b></p>
              </td>
              <td colSpan="2" style={{ width: '271.4pt', border: 'solid windowtext 1.0pt', borderLeft: 'none', backgroundColor: 'black', padding: '0in 5.4pt' }}>
                <p><b><span style={{ color: 'white' }}><img width="62" height="92" src={images.image037} alt="Trump suit card" /></span></b></p>
              </td>
            </tr>
            <tr>
              <td style={{ width: '95.8pt', border: 'solid windowtext 1.0pt', borderTop: 'none', backgroundColor: '#00B0F0', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'center' }}><b><span style={{ color: 'white' }}>1<sup>st</sup> Card Played</span></b></p>
              </td>
              <td style={{ width: '95.8pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', backgroundColor: '#00B0F0', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'center' }}><b><span style={{ color: 'white' }}>2<sup>nd</sup> Card Played</span></b></p>
              </td>
              <td style={{ width: '104.9pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', backgroundColor: '#00B0F0', padding: '0in 5.4pt' }}>
                <p><b><span style={{ color: 'white' }}>Winner/Points</span></b></p>
              </td>
              <td style={{ width: '166.5pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', backgroundColor: '#00B0F0', padding: '0in 5.4pt' }}>
                <p><b><span style={{ color: 'white' }}>Explanation</span></b></p>
              </td>
            </tr>
            <tr>
              <td style={{ width: '95.8pt', border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'center' }}><img width="61" height="90" src={images.image038} alt="First card played" /></p>
              </td>
              <td style={{ width: '95.8pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'center' }}><img width="61" height="90" src={images.image039} alt="Second card played" /></p>
              </td>
              <td style={{ width: '104.9pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0in 5.4pt' }}>
                <p>2<sup>nd</sup> Card Wins for a total of 11pts</p>
              </td>
              <td style={{ width: '166.5pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', borderBottom: 'solid windowtext 1.0pt', borderRight: 'solid windowtext 1.0pt', padding: '0in 5.4pt' }}>
                <p>The 2<sup>nd</sup> card is within the trump suit</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>
        <p><b><span style={{ backgroundColor: 'yellow' }}>Scenario 2:</span></b></p>

        <table style={{ borderCollapse: 'collapse', border: 'none' }}>
          <tbody>
            <tr>
              <td colSpan="2" style={{ width: '191.6pt', border: 'solid windowtext 1.0pt', backgroundColor: 'black', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'right' }}><b>Trump Suit</b></p>
              </td>
              <td colSpan="2" style={{ width: '271.4pt', border: 'solid windowtext 1.0pt', borderLeft: 'none', backgroundColor: 'black', padding: '0in 5.4pt' }}>
                <p><b><span style={{ color: 'white' }}><img width="60" height="90" src={images.image040} alt="Trump suit" /></span></b></p>
              </td>
            </tr>
            <tr>
              <td style={{ width: '95.8pt', border: 'solid windowtext 1.0pt', borderTop: 'none', backgroundColor: '#00B0F0', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'center' }}><b><span style={{ color: 'white' }}>1<sup>st</sup> Card Played</span></b></p>
              </td>
              <td style={{ width: '95.8pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', backgroundColor: '#00B0F0', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'center' }}><b><span style={{ color: 'white' }}>2<sup>nd</sup> Card Played</span></b></p>
              </td>
              <td style={{ width: '104.9pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', backgroundColor: '#00B0F0', padding: '0in 5.4pt' }}>
                <p><b><span style={{ color: 'white' }}>Winner/Points</span></b></p>
              </td>
              <td style={{ width: '166.5pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', backgroundColor: '#00B0F0', padding: '0in 5.4pt' }}>
                <p><b><span style={{ color: 'white' }}>Explanation</span></b></p>
              </td>
            </tr>
            <tr>
              <td style={{ width: '95.8pt', border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'center' }}><img width="61" height="90" src={images.image041} alt="First card played" /></p>
              </td>
              <td style={{ width: '95.8pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'center' }}><img width="61" height="90" src={images.image042} alt="Second card played" /></p>
              </td>
              <td style={{ width: '104.9pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', padding: '0in 5.4pt' }}>
                <p>1<sup>st</sup> Card Wins for a total of 6pts</p>
              </td>
              <td style={{ width: '166.5pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', padding: '0in 5.4pt' }}>
                <p>The 1<sup>st</sup> card is within the trump suit so the only way to beat it is by playing a higher value card within the trump suit</p>
              </td>
            </tr>
          </tbody>
        </table>    <p>&nbsp;</p>
        <p><b><span style={{ backgroundColor: 'yellow' }}>Scenario 3:</span></b></p>

        <table style={{ borderCollapse: 'collapse', border: 'none' }}>
          <tbody>
            <tr>
              <td colSpan="2" style={{ width: '191.6pt', border: 'solid windowtext 1.0pt', backgroundColor: 'black', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'right' }}><b>Trump Suit</b></p>
              </td>
              <td colSpan="2" style={{ width: '271.4pt', border: 'solid windowtext 1.0pt', borderLeft: 'none', backgroundColor: 'black', padding: '0in 5.4pt' }}>
                <p><b><span style={{ color: 'white' }}><img width="62" height="91" src={images.image043} alt="Trump suit" /></span></b></p>
              </td>
            </tr>
            <tr>
              <td style={{ width: '95.8pt', border: 'solid windowtext 1.0pt', borderTop: 'none', backgroundColor: '#00B0F0', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'center' }}><b><span style={{ color: 'white' }}>1<sup>st</sup> Card Played</span></b></p>
              </td>
              <td style={{ width: '95.8pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', backgroundColor: '#00B0F0', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'center' }}><b><span style={{ color: 'white' }}>2<sup>nd</sup> Card Played</span></b></p>
              </td>
              <td style={{ width: '104.9pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', backgroundColor: '#00B0F0', padding: '0in 5.4pt' }}>
                <p><b><span style={{ color: 'white' }}>Winner/Points</span></b></p>
              </td>
              <td style={{ width: '166.5pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', backgroundColor: '#00B0F0', padding: '0in 5.4pt' }}>
                <p><b><span style={{ color: 'white' }}>Explanation</span></b></p>
              </td>
            </tr>
            <tr>
              <td style={{ width: '95.8pt', border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'center' }}><img width="61" height="90" src={images.image044} alt="First card played" /></p>
              </td>
              <td style={{ width: '95.8pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'center' }}><img width="61" height="90" src={images.image045} alt="Second card played" /></p>
              </td>
              <td style={{ width: '104.9pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', padding: '0in 5.4pt' }}>
                <p>The 1<sup>st</sup> Card Wins for a total of 10pts</p>
              </td>
              <td style={{ width: '166.5pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', padding: '0in 5.4pt' }}>
                <p>The only way to beat the 1<sup>st</sup> card is to play a higher value card within the same suit or any card within the trump suit</p>
              </td>
            </tr>
          </tbody>
        </table>


        <p>&nbsp;</p>
        <p><b><span style={{ backgroundColor: 'yellow' }}>Scenario 4:</span></b></p>

        <table style={{ borderCollapse: 'collapse', border: 'none' }}>
          <tbody>
            <tr>
              <td colSpan="2" style={{ width: '191.6pt', border: 'solid windowtext 1.0pt', backgroundColor: 'black', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'right' }}><b>Trump Suit</b></p>
              </td>
              <td colSpan="2" style={{ width: '271.4pt', border: 'solid windowtext 1.0pt', borderLeft: 'none', backgroundColor: 'black', padding: '0in 5.4pt' }}>
                <p><b><span style={{ color: 'white' }}><img width="65" height="95" src={images.image046} alt="Trump suit" /></span></b></p>
              </td>
            </tr>
            <tr>
              <td style={{ width: '95.8pt', border: 'solid windowtext 1.0pt', borderTop: 'none', backgroundColor: '#00B0F0', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'center' }}><b><span style={{ color: 'white' }}>1<sup>st</sup> Card Played</span></b></p>
              </td>
              <td style={{ width: '95.8pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', backgroundColor: '#00B0F0', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'center' }}><b><span style={{ color: 'white' }}>2<sup>nd</sup> Card Played</span></b></p>
              </td>
              <td style={{ width: '104.9pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', backgroundColor: '#00B0F0', padding: '0in 5.4pt' }}>
                <p><b><span style={{ color: 'white' }}>Winner/Points</span></b></p>
              </td>
              <td style={{ width: '166.5pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', backgroundColor: '#00B0F0', padding: '0in 5.4pt' }}>
                <p><b><span style={{ color: 'white' }}>Explanation</span></b></p>
              </td>
            </tr>
            <tr>
              <td style={{ width: '95.8pt', border: 'solid windowtext 1.0pt', borderTop: 'none', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'center' }}><img width="61" height="90" src={images.image047} alt="First card played" /></p>
              </td>
              <td style={{ width: '95.8pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', padding: '0in 5.4pt' }}>
                <p style={{ textAlign: 'center' }}><img width="61" height="90" src={images.image048} alt="Second card played" /></p>
              </td>
              <td style={{ width: '104.9pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', padding: '0in 5.4pt' }}>
                <p>The 2<sup>nd</sup> Card Wins for a total of 0pts</p>
              </td>
              <td style={{ width: '166.5pt', border: 'solid windowtext 1.0pt', borderTop: 'none', borderLeft: 'none', padding: '0in 5.4pt' }}>
                <p>The 2<sup>nd</sup> card has a higher value even though they are both worth 0pts</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>&nbsp;</p>

        <p><b><span style={{ backgroundColor: 'yellow' }}>Game Mode Options:</span></b></p>

        <p style={{ textIndent: '-.25in' }}>
          <span style={{ fontFamily: 'Symbol' }}>®<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>
          <b><span style={{ color: '#C00000' }}>Practice Mode</span></b>
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          Cost = FREE
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          This is the perfect place to start for a newbie
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          Polish your skills for FREE by playing against Albastini’s AI anytime from anywhere
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          Players can also play as a guest
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          None of the games are tracked or logged under the leaderboard
        </p>

        <p style={{ textIndent: '-.25in' }}>
          <span style={{ fontFamily: 'Symbol' }}>®<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>
          <b><span style={{ color: '#C00000' }}>Head to Head</span></b>
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          Cost = 50, 100, 200, or 500 coins
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          This is a great place to test your skills against other players
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          Allows you to earn coins by challenging anyone anywhere anytime including your friends & family members
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          You have the option to play against a random person or a player that you have added to your list of friends
        </p>

        <p style={{ textIndent: '-.25in' }}>
          <span style={{ fontFamily: 'Symbol' }}>®<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>
          <b><span style={{ color: '#C00000' }}>Tournaments</span></b>
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          No purchase necessary to enter or win
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          A purchase will not increase your chances of winning
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          Cost = varies by contest
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          Allows you to compete against other players worldwide to see if you are the best Albastini player
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          Each match consists of 3 games against an opponent
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          The 1<sup>st</sup> player to win 2 out of the 3 games goes to the next round
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          The player who lost gets eliminated from the tournament
        </p>

        <p style={{ marginLeft: '10px', textIndent: '-.25in' }}>
          <span style={{ fontFamily: '"Courier New"' }}>o<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;</span></span>
          Prizes are awarded to the 1<sup>st</sup>, 2<sup>nd</sup>, & 3<sup>rd</sup> place winners
        </p>

        <p><b>&nbsp;</b></p>

        <p><b><span style={{ backgroundColor: 'yellow' }}>Leaderboard:</span></b></p>

        <p style={{ textIndent: '-.25in' }}>
          <span style={{ fontFamily: 'Symbol' }}>®<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>
          This is where you can view Head to Head game statistics for all players (including yourself)
        </p>

        <p style={{ textIndent: '-.25in' }}>
          <span style={{ fontFamily: 'Symbol' }}>®<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>
          You can also add friends as well as block players
        </p>

        <p><b>&nbsp;</b></p>

        <p><b><span style={{ backgroundColor: 'yellow' }}>Shop:</span></b></p>

        <p style={{ textIndent: '-.25in' }}>
          <span style={{ fontFamily: 'Symbol' }}>®<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>
          This is where you can purchase coins, themes, card decks, emojis, and more . . .
        </p>

        <p>&nbsp;</p>

        <p><b><span style={{ backgroundColor: 'yellow' }}>Settings:</span></b></p>

        <p style={{ textIndent: '-.25in' }}>
          <span style={{ fontFamily: 'Symbol' }}>®<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>
          This is where you can adjust game sounds, unblock players, as well as switch languages
        </p>

        <p>&nbsp;</p>

        <p><b><span style={{ backgroundColor: 'yellow' }}>Invite:</span></b></p>

        <p style={{ textIndent: '-.25in' }}>
          <span style={{ fontFamily: 'Symbol' }}>®<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>
          This is where you can invite others to join the Albastini family
        </p>

        <p style={{ textIndent: '-.25in' }}>
          <span style={{ fontFamily: 'Symbol' }}>®<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>
          We provide you with a referral code so that you can be rewarded with FREE coins once they signup using your code
        </p>

        <p>&nbsp;</p>

        <p><b><span style={{ backgroundColor: 'yellow' }}>Rate Us:</span></b></p>

        <p style={{ textIndent: '-.25in' }}>
          <span style={{ fontFamily: 'Symbol' }}>®<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>
          This is where you can provide us with feedback by rating us <span style={{ fontFamily: 'Wingdings' }}>J</span>
        </p>

        <p>&nbsp;</p>

        <p><b><span style={{ backgroundColor: 'yellow' }}>Notifications:</span></b></p>

        <p style={{ textIndent: '-.25in' }}>
          <span style={{ fontFamily: 'Symbol' }}>®<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;</span></span>
          This is where you see important game-related announcements
        </p>

        <p>&nbsp;</p>

        <p>&nbsp;</p>

      </div>

      {/* <div className="bg-col pb-5">
        <div className="bg-img-pol">
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="priv-pol">
                    <h1>Tournament Rules</h1>
                  </div>
            
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-12">
                  <div className="priv-pol-ph">
                    <p>
                      <span>1.</span>Login to your account.
                    </p>
                    <p>
                      <span>2.</span>Go to settings (Upper right corner).
                    </p>
                    <p>
                      <span>3.</span>Click on the "Delete Account" button (lower left corner). After clicking "Yes" on the popup, all game data will be deleted permanently.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div> */}
    </div >
  );
};
export default HowToPlay;

