import React, { useEffect, useState } from 'react';
import { Table, Breadcrumb, Input, Pagination } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import appconstant from '../../themes/appconstant';
import { useDispatch, useSelector } from 'react-redux';
import { economylogAction } from '../../redux/Action/Auth';
import { removeEmojisForSearch } from '../../common/validation';

const EconomyLogs = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items per page

  const payload = {
    userId: location.state,
    offset: (currentPage - 1) * pageSize,
    limit: pageSize,
    sort: null,
    order: null,
    search: searchValue,
  };

  const economyListData = useSelector(
    (state) => state.userManagementReducer.economyList.economyList
  );

  console.log(economyListData, "ecomomy");
  const paginationData = useSelector(
    (state) => state.userManagementReducer.economyList.paginationData
  );
  const loading = useSelector((state) => state.userManagementReducer.isLoading);

  useEffect(() => {
    dispatch(economylogAction(payload));
  }, [dispatch, location.state, currentPage, searchValue]);

  const handleSearch = (e) => {
    const searchData = removeEmojisForSearch(e?.target?.value);
    if (!searchData?.startsWith(" ")) setSearchValue(searchData);
  };

  const dataSource = economyListData?.map((e, index) => ({
    key: e._id,
    srno: index + 1 + (currentPage - 1) * pageSize,
    id: e._id,
    createdAt: new Date(e.createdAt).toLocaleString(), // Format date
    subType: e.subType,
    credit: e.credit,
    debit: e.debit,
    type: e.type.charAt(0).toUpperCase() + e.type.slice(1), // Capitalize type
    coins: e.coinsBalance,
    shop: e.shop,
    tournament:e.tournament
  }));



  const columns = [
    {
      title: 'Sr. No.',
      dataIndex: 'srno',
      key: 'srno',
      align: 'left',
      render: (value, item, index) => payload.offset + (index + 1),

    },
    {
      title: 'ID',
      dataIndex: '_id',
      key: '_id',
      align: 'left',
    },
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'left',
      render:(createdAt)=>new Date(createdAt).toLocaleString()
    },
    {
      title: 'Detail',
      dataIndex: 'subType',
      key: 'subType',
      align: 'left',
      render: (_, record) => {
        console.log(record, "deftsoft@yopmail.com	");
        return (<div>{record.subType === "shop" ? 
          record?.shop || "N/A" :
           (record.subType === "randomMatchDeduction" || record.subType === "randomMatchWin") 
           ? appconstant?.keysobj[_] + " (" + record._id + ")" :
            (record.subType === "tournamentWin" ||record.subType === "tournamentRegister")?
             appconstant?.keysobj[_] +" ("+record.tournament+")":
             (record.subType === "signUpBonus")?
             appconstant?.keysobj[_] 
             :
             appconstant?.keysobj[_]}</div>)
      }

    },
    {
      title: 'Credit',
      dataIndex: 'credit',
      key: 'credit',
      align: 'left',
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
      key: 'debit',
      align: 'left',
    },
    {
      title: 'Coins Balance',
      dataIndex: 'coinsBalance',
      key: 'coinsBalance',
      align: 'left',
    },
  ];

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link to="/usermanagement">
            {appconstant.UserManagement}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Coins History</Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              Coins History
            </h6>
            <Input
              placeholder="Search"
              className="search-bar"
              onChange={handleSearch}
            />
          </div>

          <Table
            columns={columns}
            dataSource={economyListData}
            pagination={false}
            loading={loading}
          />

          <Pagination
            current={currentPage}
            pageSize={pageSize}
            total={paginationData?.totalCount || 0}
            onChange={(page) => setCurrentPage(page)}
            className="pagination-wrapper"
            showSizeChanger={false}
          />
        </div>
      </div>
    </div>
  );
};

export default EconomyLogs;
