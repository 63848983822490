import { msgConstant, reduxConstant } from "../../constants/appconstant";
import { put, call, takeLatest } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  userListApi,
  blockUserApi,
  viewUserApi,
  editUserApi,
  userAddCoinApi,
  economylogsApi,
} from "../Api/index";

function* userListSaga(action) {
  try {
    const response = yield call(userListApi, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      yield put({
        type: reduxConstant.API_USER_LIST_SUCCESS,
        result: response?.result,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
  }
}

function* economylogsSaga(action) {
  try {
    const response = yield call(economylogsApi, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      yield put({
        type: reduxConstant.ECONOMY_LOG_SUCCESS,
        result: response?.result,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.ECONOMY_LOG_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.ECONOMY_LOG_FAIL});
  }
}

function* blockUserSaga(action) {
  try {
    const response = yield call(blockUserApi, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      toast.success(response.result.message);
      yield put({
        type: reduxConstant.API_BLOCK_USER_SUCCESS,
        result: action.data,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
  }
}

function* addCoinUserSaga(action) {
  try {
    const response = yield call(userAddCoinApi, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      toast.success(response.result.message);
      yield put({
        type: reduxConstant.API_USER_ADD_COIN_SUCCESS,
        result: action.data,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
  }
}

function* viewUserSaga(action) {
  try {
    const response = yield call(viewUserApi, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      yield put({
        type: reduxConstant.API_VIEW_USER_SUCCESS,
        result: response.result,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
  }
}

function* editUserSaga(action) {
  try {
    const response = yield call(editUserApi, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      toast.success(response.result.message);
      yield put({
        type: reduxConstant.API_EDIT_USER_SUCCESS,
        result: response.result,
      });
      action.navigate("/usermanagement");
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
  }
}

export default function* UserManagementSaga() {
  yield takeLatest(reduxConstant.API_User_List_LOAD, userListSaga);
  yield takeLatest(reduxConstant.API_BLOCK_USER_LOAD, blockUserSaga);
  yield takeLatest(reduxConstant.API_VIEW_USER_LOAD, viewUserSaga);
  yield takeLatest(reduxConstant.API_EDIT_USER_LOAD, editUserSaga);
  yield takeLatest(reduxConstant.API_USER_ADD_COIN_LOAD, addCoinUserSaga);
  yield takeLatest(reduxConstant.ECONOMY_LOG_LOAD , economylogsSaga)
}
