import { reduxConstant } from "../../constants/appconstant"

const initialState = {
    isLoading: false,
    versionDetails: {},
}

export const versionManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        // version details
        case reduxConstant.API_VERSION_DETAILS_LOAD: return { ...state, isLoading: true };
        case reduxConstant.API_VERSION_DETAILS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                versionDetails: action?.result,
            };
        //Fail
        case reduxConstant.API_VERSION_DETAILS_FAIL: 
        return { 
            ...state, 
            isLoading: false 
        };

        case reduxConstant.UPDATE_VERSION_LOAD: 
        return { 
            ...state, 
            isLoading: true 
        };
        case reduxConstant.UPDATE_VERSION_SUCCESS: 
        return { 
            ...state, 
            isLoading: false,     
        };
        case reduxConstant.UPDATE_VERSION_FAIL: 
        return { 
            ...state, 
            isLoading: false 
        };

        case reduxConstant.API_UPDATE_VERSION_LOAD: return { ...state, isLoading: true };
        case reduxConstant.API_UPDATE_VERSION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                        };

        //Fail
        case reduxConstant.API_UPDATE_VERSION_FAIL: return { ...state, isLoading: false };

        //default
        default: return { ...state };
    }
}