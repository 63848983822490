import React, { useState, useEffect } from "react";
import { Image, Pagination, Select, Spin } from "antd";
import images from "../../themes/appImage";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal, Tabs } from "antd";
import appconstant from "../../themes/appconstant";
import { useDispatch, useSelector } from "react-redux";
import { shopListAction } from "../../redux/Action/Auth";
import { reduxConstant } from "../../constants/appconstant";
import { addItemSchema, removeEmojisForSearch } from "../../common/validation";
import { useFormik } from "formik";
import { ExclamationCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import { TabPane } from "react-bootstrap";

const ShopManagementScreen = () => {
  const [activeTab, setActiveTab] = useState("1");
  const dispatch = useDispatch();
  const shopListData = useSelector(
    (state) => state.shopManagementReducer.shopList.shopList
  );

  const paginationData = useSelector(
    (state) => state.shopManagementReducer.shopList.paginationData
  );

  const loading = useSelector((state) => state.shopManagementReducer.isLoading);
  const [payload, setPayload] = useState({
    offset: 0,
    limit: 10,
    sort: null,
    order: null,
    type: "skins",
    search: "",
  });
  const [searchValue, setSearchValue] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [payloadImage, setPayloadImage] = useState(null);
  const [image, setImage] = useState(null);

  const handleChange = (col, page) => {
    const tempPayload = { ...payload };

    if (col && col.columnKey) {
      tempPayload["sort"] = col?.columnKey || payload?.sort;
      tempPayload["order"] =
        col?.order === "ascend"
          ? 1
          : col?.order === "descend"
          ? -1
          : payload?.order;
    } else if (page) {
      tempPayload["offset"] = (page - 1) * 10;
    }
    setPayload(tempPayload);
    console.log("PAYLOAD", tempPayload);
    dispatch(shopListAction({ ...tempPayload }));
  };
  useEffect(() => {
    dispatch(shopListAction(payload));
    return () => Modal.destroyAll();
  }, [activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleTabChange = (key) => {
    setActiveTab(key);
    setSearchValue("")
    setPayload((prevPayload) => ({
      ...prevPayload,
      offset:0,
      type:
        key == "1"
          ? "skins"
          : key == 2
          ? "cardDecks"
          : key == 3
          ? "emojis"
          : "",
    }));
  };

  const formik = useFormik({
    initialValues: { itemName: "", cost: "", itemType: "", imageUrl: "" },
    enableReinitialize: true,
    validationSchema: addItemSchema,
    onSubmit: (values) => {
      let payload = new FormData();
      payload.append("itemName", values.itemName);
      payload.append("cost", values.cost);
      payload.append("imageUrl", payloadImage);
    },
  });

  const imageHandle = (e) => {
    if (e.target.files[0]) {
      setImage(URL.createObjectURL(e.target.files[0]));
      setPayloadImage(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (searchValue?.length <= 50)
      dispatch(shopListAction({ ...payload, search: searchValue, offset: 0 }));
  }, [searchValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (e) => {
    const serachData = removeEmojisForSearch(e?.target?.value);
    if (!serachData?.startsWith(" ")) setSearchValue(serachData);
  };

  const showModal = () => setModalVisible(true);
  const hideModal = () => {
    formik.resetForm();
    setModalVisible(false);
  };

  const dataSource = shopListData?.map((e) => {
    return {
      _id: e?._id,
      imageUrl: e?.imageUrl,
      themNameEng: e?.nameEn,
      themeName: e?.nameSw,
      price: e?.cost,
    };
  });

  const columns = [
    {
      title: "Sr. No.",
      width: "250px",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (value, item, index) => ((paginationData.currentPage-1)*10) + (index + 1),
    },

    {
      title: "Image",
      width: "250px",
      dataIndex: "gameid",
      key: "gameid ",
      sorter: false,
      render: (title, data, index) => (
        <>
          {
            <Image
              alt=""
              src={
                data?.imageUrl
                  ? `${reduxConstant.IMAGE_URL}/${data?.imageUrl}`
                  : "N/A"
              }
            ></Image>
          }
        </>
      ),
    },

    {
      title: "Theme Name (English)",
      width: "250px",
      dataIndex: "firstplayer",
      key: "firstplayer",
      sorter: true,
      render: (title, data, index) => <>{data?.themNameEng || "N/A"}</>,
    },
    {
      title: "Theme Name (Swahili)",
      width: "250px",
      dataIndex: "secondplayer",
      key: "secondplayer",
      sorter: true,
      render: (title, data, index) => <>{data?.themeName || "N/A"}</>,
    },
    {
      title: "Price",
      width: "250px",
      dataIndex: "secondplayer",
      key: "secondplayer",
      sorter: true,
      render: (title, data, index) => <>{data?.price || "N/A"}</>,
    },
    {
      title: "Action",
      dataIndex: "Posted",
      key: "Posted",
      render: (title, data, index) => {
        return (
          <div>
            <Link to="/editTheme" state={data?._id}>
              <button className="edit-btn" type="submit">
                {appconstant.edit}
              </button>
            </Link>
          </div>
        );
      },
    },
  ];
  const columns2 = [
    {
      title: "Sr. No.",
      width: "250px",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (value, item, index) => ((paginationData.currentPage-1)*10) + (index + 1),
    },

    {
      title: "Image",
      width: "250px",
      dataIndex: "gameid",
      key: "gameid ",
      sorter: false,
      render: (title, data, index) => (
        <>
          {
            <Image
              alt=""
              src={
                data?.imageUrl
                  ? `${reduxConstant.IMAGE_URL}/${data?.imageUrl}`
                  : "N/A"
              }
            ></Image>
          }
        </>
      ),
    },

    {
      title: "Card Background Name (English)",
      width: "250px",
      dataIndex: "firstplayer",
      key: "firstplayer",
      sorter: true,
      render: (title, data, index) => <>{data?.themNameEng || "N/A"}</>,
    },
    {
      title: "Card Background Name (Swahili)",
      width: "250px",
      dataIndex: "secondplayer",
      key: "secondplayer",
      sorter: true,
      render: (title, data, index) => <>{data?.themeName || "N/A"}</>,
    },
    {
      title: "Price",
      width: "250px",
      dataIndex: "secondplayer",
      key: "secondplayer",
      sorter: true,
      render: (title, data, index) => <>{data?.price || "N/A"}</>,
    },
    {
      title: "Action",
      dataIndex: "Posted",
      key: "Posted",
      render: (title, data, index) => {
        return (
          <div>
            <Link to="/editCard" state={data?._id}>
              <button className="edit-btn" type="submit">
                {appconstant.edit}
              </button>
            </Link>
          </div>
        );
      },
    },
  ];
  const columns3 = [
    {
      title: "Sr. No.",
      width: "250px",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (value, item, index) => ((paginationData.currentPage-1)*10) + (index + 1),
    },

    {
      title: "Image",
      width: "250px",
      dataIndex: "gameid",
      key: "gameid ",
      sorter: false,
      render: (title, data, index) => (
        <>
          {
            <Image
              alt=""
              src={
                data?.imageUrl
                  ? `${reduxConstant.IMAGE_URL}/${data?.imageUrl}`
                  : "N/A"
              }
            ></Image>
          }
        </>
      ),
    },

    {
      title: "Emoji Name (English)",
      width: "250px",
      dataIndex: "firstplayer",
      key: "firstplayer",
      sorter: true,
      render: (title, data, index) => <>{data?.themNameEng || "N/A"}</>,
    },
    {
      title: "Emoji Name (Swahili)",
      width: "250px",
      dataIndex: "secondplayer",
      key: "secondplayer",
      sorter: true,
      render: (title, data, index) => <>{data?.themeName || "N/A"}</>,
    },
    {
      title: "Price",
      width: "250px",
      dataIndex: "secondplayer",
      key: "secondplayer",
      sorter: true,
      render: (title, data, index) => <>{data?.price || "N/A"}</>,
    },
    {
      title: "Action",
      dataIndex: "Posted",
      key: "Posted",
      render: (title, data, index) => {
        return (
          <div>
            <Link to="/editEmoji" state={data?._id}>
              <button className="edit-btn" type="submit">
                {appconstant.edit}
              </button>
            </Link>
          </div>
        );
      },
    },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    // const token = localStorage.token;
    // token && navigate("/gamemanagement");
    document.title = "Albastini";
  }, []);

  return (
    <div>
      {/* <BreadcrumbFn
                path={["dashboard", "usermanagement"]}
                pathName={[
                    <FontAwesomeIcon icon={faHome} />,
                    appconstant.UserManagemen,
                    
                ]}

            /> */}

      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.ShopManagement}</Breadcrumb.Item>
      </Breadcrumb>
      <Tabs
        activeKey={activeTab}
        onChange={handleTabChange}
        className="horizontal-tabs"
      >
        <TabPane tab="Theme management" key="1">
          {activeTab == "1" && (
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 360 }}
            >
              <div className="content-e">
                <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                  <h6 className="text-white text-capitalize ps-3">
                    {appconstant.ThemeMangement}
                  </h6>

                  {/* <button
              type="submit"
              className="add-item-button"
              onClick={() => {
                showModal();
              }}
            >
              {appconstant.addShopItem}
            </button> */}
                  <input
                    type="text"
                    onChange={handleSearch}
                    className="iocn-search  search-bar"
                    placeholder="Search"
                    value={searchValue}
                  />
                </div>
                <div>
                  <Link to="/createtheme">
                    <button type="submit" className="button-list ">
                      {appconstant.createTheme}
                    </button>
                  </Link>
                </div>
                <Table
                  className="unser-manage-table"
                  dataSource={dataSource?.map((item, index) => ({
                    ...item,
                    key: index,
                  }))}
                  columns={columns}
                  showSorterTooltip={false}
                  loading={loading}
                  pagination={false}
                  onChange={(x, y, col) => handleChange(col)}
                />
                <Pagination
                  showSizeChanger={false}
                  showTitle={false}
                  className="pagination-wrapper"
                  total={paginationData?.totalCount}
                  current={paginationData?.currentPage}
                  onChange={(page) => handleChange(null, page)}
                />
                <Modal
                  title={
                    <div className="modal-header">
                      <ExclamationCircleOutlined className="modal-icon" />
                      {appconstant.addShopItem}
                    </div>
                  }
                  centered={true}
                  maskClosable={true}
                  onCancel={hideModal}
                  className="create-admin-modal"
                  closable={false}
                  visible={modalVisible}
                  onSubmit={hideModal}
                  footer={false}
                >
                  <div className="create-item-form-group">
                    <div className="form-header">
                      <div className="main-div">
                        <form
                          className="create-item-form-wrapper"
                          onSubmit={formik.handleSubmit}
                        >
                          <div className="wrapper_line">
                            <div className="form-group for-bottom-margin">
                              <label>{appconstant.itemName}</label>
                              <div className="form-input-wrapper">
                                <div className="border-input">
                                  <input
                                    className="input-wrapper"
                                    name="itemName"
                                    type="text"
                                    placeholder={appconstant.iteamName}
                                    value={formik.values.itemName}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.startsWith(" ") ||
                                        !/^[A-Za-z0-9 ]*$/.test(e.target.value)
                                      )
                                        e.preventDefault();
                                      else formik.handleChange(e);
                                    }}
                                  />
                                </div>
                                <div className="add-item-validation-error">
                                  {formik.errors.itemName ?? null}
                                </div>
                              </div>
                            </div>

                            <div className="form-group for-bottom-margin">
                              <label>{appconstant.cost}</label>
                              <div className="form-input-wrapper">
                                <div className="border-input">
                                  <input
                                    className="input-wrapper"
                                    name="cost"
                                    type="text"
                                    placeholder={appconstant.cost}
                                    value={formik.values.cost}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.startsWith(" ") ||
                                        !/^[0-9]*$/.test(e.target.value)
                                      )
                                        e.preventDefault();
                                      else formik.handleChange(e);
                                    }}
                                  />
                                </div>
                                <div className="add-item-validation-error">
                                  {formik.errors.cost ?? null}
                                </div>
                              </div>
                            </div>

                            <div className="form-group for-bottom-margin">
                              <label>{appconstant.type}</label>
                              <div className="form-input-wrapper">
                                <div className="border-input">
                                  <Select
                                    id={"id"}
                                    showArrow
                                    // value={"nnn"}
                                    className="input-wrapper"
                                    style={{ width: "100%" }}
                                    name="itemType"
                                    onChange={(e) => {
                                      formik.setFieldValue("itemType", e);
                                    }}
                                    showSearch={false}
                                    placeholder="Select Item Type"
                                    options={[
                                      { value: "option1", label: "Option 1" },
                                      { value: "option2", label: "Option 2" },
                                      { value: "option3", label: "Option 3" },
                                      { value: "option4", label: "Option 4" },
                                      { value: "option5", label: "Option 5" },
                                      { value: "option6", label: "Option 6" },
                                    ]}
                                  />
                                </div>
                                <div className="add-item-validation-error">
                                  {formik.errors.itemType ?? null}
                                </div>
                              </div>
                            </div>

                            <div className="form-group for-bottom-margin for-top datacenter">
                              <label
                                htmlFor="file-input"
                                className="user-detail-image-label"
                                style={{
                                  borderRadius: "100%",
                                  overflow: "hidden",
                                }}
                              >
                                {!image && (
                                  <div className="datacenter-icon">
                                    <img
                                      alt=""
                                      src={images.pluss}
                                      title="Click to edit image"
                                    />
                                  </div>
                                )}
                                <img
                                  alt=""
                                  src={image ? image : images.usermessage}
                                  name="imageUrl"
                                  style={{
                                    width: "100px",
                                    Height: "200px",
                                    borderRadius: "100%",
                                    overflow: "hidden",
                                  }}
                                />
                              </label>
                              <input
                                id="file-input"
                                type="file"
                                onChange={imageHandle}
                                className="hidden"
                                accept="image/png, image/jpg, image/jpeg"
                              />
                              <div className="add-item-validation-error">
                                {formik.errors.imageUrl ?? null}
                              </div>
                            </div>

                            <div className="up-cent">
                              <button
                                className="add-item-button-form"
                                disabled={loading}
                                onClick={formik.handleSubmit}
                                type="submit"
                              >
                                {loading ? (
                                  <Spin indicator={<LoadingOutlined spin />} />
                                ) : (
                                  appconstant.addShopItem
                                )}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          )}
        </TabPane>
        <TabPane tab="Card Background" key="2">
          {activeTab == 2 && (
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 360 }}
            >
              <div className="content-e">
                <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                  <h6 className="text-white text-capitalize ps-3">
                    {appconstant.CardManagement}
                  </h6>

                  {/* <button
              type="submit"
              className="add-item-button"
              onClick={() => {
                showModal();
              }}
            >
              {appconstant.addShopItem}
            </button> */}
                  <input
                    type="text"
                    onChange={handleSearch}
                    className="iocn-search  search-bar"
                    placeholder="Search"
                    value={searchValue}
                  />
                </div>
                <div>
                  <Link to="/createcard">
                    <button type="submit" className="button-list ">
                      {appconstant.createCards}
                    </button>
                  </Link>
                </div>
                <Table
                  className="unser-manage-table"
                  dataSource={dataSource?.map((item, index) => ({
                    ...item,
                    key: index,
                  }))}
                  columns={columns2}
                  showSorterTooltip={false}
                  loading={loading}
                  pagination={false}
                  onChange={(x, y, col) => handleChange(col)}
                  style={{ width: "100%" }} // Set the width to 100%
                />
                <Pagination
                  showSizeChanger={false}
                  showTitle={false}
                  className="pagination-wrapper"
                  total={paginationData?.totalCount}
                  current={paginationData?.currentPage}
                  onChange={(page) => handleChange(null, page)}
                />
                <Modal
                  title={
                    <div className="modal-header">
                      <ExclamationCircleOutlined className="modal-icon" />
                      {appconstant.addShopItem}
                    </div>
                  }
                  centered={true}
                  maskClosable={true}
                  onCancel={hideModal}
                  className="create-admin-modal"
                  closable={false}
                  visible={modalVisible}
                  onSubmit={hideModal}
                  footer={false}
                >
                  <div className="create-item-form-group">
                    <div className="form-header">
                      <div className="main-div">
                        <form
                          className="create-item-form-wrapper"
                          onSubmit={formik.handleSubmit}
                        >
                          <div className="wrapper_line">
                            <div className="form-group for-bottom-margin">
                              <label>{appconstant.itemName}</label>
                              <div className="form-input-wrapper">
                                <div className="border-input">
                                  <input
                                    className="input-wrapper"
                                    name="itemName"
                                    type="text"
                                    placeholder={appconstant.iteamName}
                                    value={formik.values.itemName}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.startsWith(" ") ||
                                        !/^[A-Za-z0-9 ]*$/.test(e.target.value)
                                      )
                                        e.preventDefault();
                                      else formik.handleChange(e);
                                    }}
                                  />
                                </div>
                                <div className="add-item-validation-error">
                                  {formik.errors.itemName ?? null}
                                </div>
                              </div>
                            </div>

                            <div className="form-group for-bottom-margin">
                              <label>{appconstant.cost}</label>
                              <div className="form-input-wrapper">
                                <div className="border-input">
                                  <input
                                    className="input-wrapper"
                                    name="cost"
                                    type="text"
                                    placeholder={appconstant.cost}
                                    value={formik.values.cost}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.startsWith(" ") ||
                                        !/^[0-9]*$/.test(e.target.value)
                                      )
                                        e.preventDefault();
                                      else formik.handleChange(e);
                                    }}
                                  />
                                </div>
                                <div className="add-item-validation-error">
                                  {formik.errors.cost ?? null}
                                </div>
                              </div>
                            </div>

                            <div className="form-group for-bottom-margin">
                              <label>{appconstant.type}</label>
                              <div className="form-input-wrapper">
                                <div className="border-input">
                                  <Select
                                    id={"id"}
                                    showArrow
                                    // value={"nnn"}
                                    className="input-wrapper"
                                    style={{ width: "100%" }}
                                    name="itemType"
                                    onChange={(e) => {
                                      formik.setFieldValue("itemType", e);
                                    }}
                                    showSearch={false}
                                    placeholder="Select Item Type"
                                    options={[
                                      { value: "option1", label: "Option 1" },
                                      { value: "option2", label: "Option 2" },
                                      { value: "option3", label: "Option 3" },
                                      { value: "option4", label: "Option 4" },
                                      { value: "option5", label: "Option 5" },
                                      { value: "option6", label: "Option 6" },
                                    ]}
                                  />
                                </div>
                                <div className="add-item-validation-error">
                                  {formik.errors.itemType ?? null}
                                </div>
                              </div>
                            </div>

                            <div className="form-group for-bottom-margin for-top datacenter">
                              <label
                                htmlFor="file-input"
                                className="user-detail-image-label"
                                style={{
                                  borderRadius: "100%",
                                  overflow: "hidden",
                                }}
                              >
                                {!image && (
                                  <div className="datacenter-icon">
                                    <img
                                      alt=""
                                      src={images.pluss}
                                      title="Click to edit image"
                                    />
                                  </div>
                                )}
                                <img
                                  alt=""
                                  src={image ? image : images.usermessage}
                                  name="imageUrl"
                                  style={{
                                    width: "100px",
                                    Height: "200px",
                                    borderRadius: "100%",
                                    overflow: "hidden",
                                  }}
                                />
                              </label>
                              <input
                                id="file-input"
                                type="file"
                                onChange={imageHandle}
                                className="hidden"
                                accept="image/png, image/jpg, image/jpeg"
                              />
                              <div className="add-item-validation-error">
                                {formik.errors.imageUrl ?? null}
                              </div>
                            </div>

                            <div className="up-cent">
                              <button
                                className="add-item-button-form"
                                disabled={loading}
                                onClick={formik.handleSubmit}
                                type="submit"
                              >
                                {loading ? (
                                  <Spin indicator={<LoadingOutlined spin />} />
                                ) : (
                                  appconstant.addShopItem
                                )}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          )}
        </TabPane>
        <TabPane tab="Emoji" key="3">
          {activeTab == 3 && (
            <div
              className="site-layout-background"
              style={{ padding: 24, minHeight: 360 }}
            >
              <div className="content-e">
                <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                  <h6 className="text-white text-capitalize ps-3">
                    {appconstant.Emoji}
                  </h6>

                  {/* <button
              type="submit"
              className="add-item-button"
              onClick={() => {
                showModal();
              }}
            >
              {appconstant.addShopItem}
            </button> */}

                  <input
                    type="text"
                    onChange={handleSearch}
                    className="iocn-search  search-bar"
                    placeholder="Search"
                    value={searchValue}
                  />
                </div>
                <div>
                  <Link to="/createemoji">
                    <button type="submit" className="button-list ">
                      {appconstant.createEmoji}
                    </button>
                  </Link>
                </div>
                <Table
                  className="unser-manage-table"
                  dataSource={dataSource?.map((item, index) => ({
                    ...item,
                    key: index,
                  }))}
                  columns={columns3}
                  showSorterTooltip={false}
                  loading={loading}
                  pagination={false}
                  onChange={(x, y, col) => handleChange(col)}
                />
                <Pagination
                  showSizeChanger={false}
                  showTitle={false}
                  className="pagination-wrapper"
                  total={paginationData?.totalCount}
                  current={paginationData?.currentPage}
                  onChange={(page) => handleChange(null, page)}
                />
                <Modal
                  title={
                    <div className="modal-header">
                      <ExclamationCircleOutlined className="modal-icon" />
                      {appconstant.addShopItem}
                    </div>
                  }
                  centered={true}
                  maskClosable={true}
                  onCancel={hideModal}
                  className="create-admin-modal"
                  closable={false}
                  visible={modalVisible}
                  onSubmit={hideModal}
                  footer={false}
                >
                  <div className="create-item-form-group">
                    <div className="form-header">
                      <div className="main-div">
                        <form
                          className="create-item-form-wrapper"
                          onSubmit={formik.handleSubmit}
                        >
                          <div className="wrapper_line">
                            <div className="form-group for-bottom-margin">
                              <label>{appconstant.itemName}</label>
                              <div className="form-input-wrapper">
                                <div className="border-input">
                                  <input
                                    className="input-wrapper"
                                    name="itemName"
                                    type="text"
                                    placeholder={appconstant.iteamName}
                                    value={formik.values.itemName}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.startsWith(" ") ||
                                        !/^[A-Za-z0-9 ]*$/.test(e.target.value)
                                      )
                                        e.preventDefault();
                                      else formik.handleChange(e);
                                    }}
                                  />
                                </div>
                                <div className="add-item-validation-error">
                                  {formik.errors.itemName ?? null}
                                </div>
                              </div>
                            </div>

                            <div className="form-group for-bottom-margin">
                              <label>{appconstant.cost}</label>
                              <div className="form-input-wrapper">
                                <div className="border-input">
                                  <input
                                    className="input-wrapper"
                                    name="cost"
                                    type="text"
                                    placeholder={appconstant.cost}
                                    value={formik.values.cost}
                                    onChange={(e) => {
                                      if (
                                        e.target.value.startsWith(" ") ||
                                        !/^[0-9]*$/.test(e.target.value)
                                      )
                                        e.preventDefault();
                                      else formik.handleChange(e);
                                    }}
                                  />
                                </div>
                                <div className="add-item-validation-error">
                                  {formik.errors.cost ?? null}
                                </div>
                              </div>
                            </div>

                            <div className="form-group for-bottom-margin">
                              <label>{appconstant.type}</label>
                              <div className="form-input-wrapper">
                                <div className="border-input">
                                  <Select
                                    id={"id"}
                                    showArrow
                                    // value={"nnn"}
                                    className="input-wrapper"
                                    style={{ width: "100%" }}
                                    name="itemType"
                                    onChange={(e) => {
                                      formik.setFieldValue("itemType", e);
                                    }}
                                    showSearch={false}
                                    placeholder="Select Item Type"
                                    options={[
                                      { value: "option1", label: "Option 1" },
                                      { value: "option2", label: "Option 2" },
                                      { value: "option3", label: "Option 3" },
                                      { value: "option4", label: "Option 4" },
                                      { value: "option5", label: "Option 5" },
                                      { value: "option6", label: "Option 6" },
                                    ]}
                                  />
                                </div>
                                <div className="add-item-validation-error">
                                  {formik.errors.itemType ?? null}
                                </div>
                              </div>
                            </div>

                            <div className="form-group for-bottom-margin for-top datacenter">
                              <label
                                htmlFor="file-input"
                                className="user-detail-image-label"
                                style={{
                                  borderRadius: "100%",
                                  overflow: "hidden",
                                }}
                              >
                                {!image && (
                                  <div className="datacenter-icon">
                                    <img
                                      alt=""
                                      src={images.pluss}
                                      title="Click to edit image"
                                    />
                                  </div>
                                )}
                                <img
                                  alt=""
                                  src={image ? image : images.usermessage}
                                  name="imageUrl"
                                  style={{
                                    width: "100px",
                                    Height: "200px",
                                    borderRadius: "100%",
                                    overflow: "hidden",
                                  }}
                                />
                              </label>
                              <input
                                id="file-input"
                                type="file"
                                onChange={imageHandle}
                                className="hidden"
                                accept="image/png, image/jpg, image/jpeg"
                              />
                              <div className="add-item-validation-error">
                                {formik.errors.imageUrl ?? null}
                              </div>
                            </div>

                            <div className="up-cent">
                              <button
                                className="add-item-button-form"
                                disabled={loading}
                                onClick={formik.handleSubmit}
                                type="submit"
                              >
                                {loading ? (
                                  <Spin indicator={<LoadingOutlined spin />} />
                                ) : (
                                  appconstant.addShopItem
                                )}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </Modal>
              </div>
            </div>
          )}
        </TabPane>
      </Tabs>
    </div>
  );
};
export default ShopManagementScreen;
