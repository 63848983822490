import React, { useState, useEffect } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from 'antd';
import appconstant from "../../themes/appconstant";
import ModalPop from "../../common/modal";
import Textfiled from "../../common";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { notificationEdit } from "../../redux/Action/Auth";

const EditNotification = () => {

    const dispatch = useDispatch()
    const data = useLocation()
    const notificationData = data.state.data
    const navigate = useNavigate()
    const [isModalVisible, setIsModalVisible] = useState(false);

    const formik = useFormik({
        initialValues: {
            title: notificationData?.title || "",
            description: notificationData?.description || ""
        },
        validationSchema: Yup.object().shape({
            title: Yup.string()
                .required("Please enter title.")
                .test("trim", "Please enter valid title.",
                    (value) => {
                        value = value?.replace(/\s+/g, "")
                        return ! /[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(value)
                    })
                .min(3, "Title must contain at least 3 characters.")
                .max(255),
            description: Yup.string()
                .required("Please enter description.")
                .test("trim", "Please enter valid description.",
                    (value) => {
                        value = value?.replace(/\s+/g, "")
                        return ! /[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(value)
                    })
                .min(3, "Description must contain at least 3 characters.")
                .max(255),
        }),


        onSubmit: (values) => {

            dispatch(notificationEdit({ id: notificationData._id, ...values }, navigate))
        }
    })

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const successBtn = (
        <>
            <div className="forgot-text-btn">
                <Link to='/usermanagement'>
                    <button className='forgot_text'>

                        Ok

                    </button>
                </Link>

            </div>
        </>
    )


    const handleOk = () => {

        setIsModalVisible(false);

    };

    const fragment = (
        <>
            <div className="success-deta">
                User details updated successfully.</div>
        </>
    )


    useEffect(() => {
        document.title = 'Albastini';
        window.scrollTo(0, 0)

    }, [])

    return (

        <div>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>

                <Breadcrumb.Item><Link to="/notificationmanagement">{appconstant.notifcationManagement} </Link></Breadcrumb.Item>

                <Breadcrumb.Item>{appconstant.editNotification}</Breadcrumb.Item>

            </Breadcrumb>



            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <div className="content-e butoo-pa">
                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                        <h6 className="text-white text-capitalize ps-3">{appconstant.editNotification}</h6>
                    </div>

                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                        <h6 className="text-white text-capitalize ps-3  mt-title">{appconstant.titile}</h6>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="key">
                            <Textfiled
                                className='form-control'
                                type='text'
                                name='title'
                                placeholder='Title'
                                value={formik.values.title}
                                onChange={formik.handleChange}
                            />
                            {formik.errors.title ? (
                                <div style={{ color: "red" }}>{formik.errors.title}</div>
                            ) : null}
                        </div>

                        <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 ">
                            <h6 className="text-white text-capitalize ps-3  mt-title">{appconstant.description}</h6>
                        </div>
                        <div className="about-us">
                            <textarea rows="4" cols="50"
                                placeholder='Description '
                                name='description'
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                maxLength={255}
                            >
                            </textarea>
                            {formik.errors.description ? (
                                <div style={{ color: "red" }}>{formik.errors.description}</div>
                            ) : null}
                        </div>
                        <div className='up-btn'>
                            <button type="submit" className="button-list">{appconstant.update} </button>
                        </div>
                    </form>
                </div>

            </div>
            {isModalVisible && <ModalPop

                content={fragment}
                btnSuccess={successBtn}
                btnCancel={true}
                handleSuccess={handleOk}
                handleCancel={handleCancel}
                isVisible={isModalVisible}
                center='deta-first-model'

            />}
        </div>

    )
}
export default EditNotification
