import { combineReducers } from "redux";
import { authReducer } from "./Auth";
import { notificationReducer } from "./notification";
import { userManagementReducer } from "./userManagement";
import { shopManagementReducer } from "./shopManagement";
import { tournamentReducer } from "./tournament";
import { gameManagementReducer } from "./gameManagment";
import { versionManagementReducer } from "./versionManagement";

// import appconstant from "../../themes/appconstant";
const rootReducer = combineReducers({
  authReducer,
  notificationReducer,
  userManagementReducer,
  shopManagementReducer,
  tournamentReducer,
  gameManagementReducer,
  versionManagementReducer
});

// const rootReducer = (state, action) => {
//     if (action.type === reduxConstant.RESET_STORE) state = undefined;
//     return appReducer(state, action);
// };
export default rootReducer;
