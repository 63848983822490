import React, { useEffect } from "react"
import { Link } from "react-router-dom";
import appconstant from "../../themes/appconstant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from 'antd';

import { useFormik } from "formik";
import * as Yup from "yup";
import { changePassword } from '../../redux/Action/Auth'
import { useDispatch } from "react-redux";

const Changepassword = () => {

    const dispatch = useDispatch()
    const removeEmojis = (string) => {
        let regex = /(?:[\u00A9\u00AE\u203C\u2049\u2122\u2139\u2194-\u2199\u21A9-\u21AA\u231A-\u231B\u2328\u23CF\u23E9-\u23F3\u23F8-\u23FA\u24C2\u25AA-\u25AB\u25B6\u25C0\u25FB-\u25FE\u2600-\u2604\u260E\u2611\u2614-\u2615\u2618\u261D\u2620\u2622-\u2623\u2626\u262A\u262E-\u262F\u2638-\u263A\u2640\u2642\u2648-\u2653\u2660\u2663\u2665-\u2666\u2668\u267B\u267F\u2692-\u2697\u2699\u269B-\u269C\u26A0-\u26A1\u26AA-\u26AB\u26B0-\u26B1\u26BD-\u26BE\u26C4-\u26C5\u26C8\u26CE-\u26CF\u26D1\u26D3-\u26D4\u26E9-\u26EA\u26F0-\u26F5\u26F7-\u26FA\u26FD\u2702\u2705\u2708-\u270D\u270F\u2712\u2714\u2716\u271D\u2721\u2728\u2733-\u2734\u2744\u2747\u274C\u274E\u2753-\u2755\u2757\u2763-\u2764\u2795-\u2797\u27A1\u27B0\u27BF\u2934-\u2935\u2B05-\u2B07\u2B1B-\u2B1C\u2B50\u2B55\u3030\u303D\u3297\u3299]|(?:\uD83C[\uDC04\uDCCF\uDD70-\uDD71\uDD7E-\uDD7F\uDD8E\uDD91-\uDD9A\uDDE6-\uDDFF\uDE01-\uDE02\uDE1A\uDE2F\uDE32-\uDE3A\uDE50-\uDE51\uDF00-\uDF21\uDF24-\uDF93\uDF96-\uDF97\uDF99-\uDF9B\uDF9E-\uDFF0\uDFF3-\uDFF5\uDFF7-\uDFFF]|\uD83D[\uDC00-\uDCFD\uDCFF-\uDD3D\uDD49-\uDD4E\uDD50-\uDD67\uDD6F-\uDD70\uDD73-\uDD7A\uDD87\uDD8A-\uDD8D\uDD90\uDD95-\uDD96\uDDA4-\uDDA5\uDDA8\uDDB1-\uDDB2\uDDBC\uDDC2-\uDDC4\uDDD1-\uDDD3\uDDDC-\uDDDE\uDDE1\uDDE3\uDDE8\uDDEF\uDDF3\uDDFA-\uDE4F\uDE80-\uDEC5\uDECB-\uDED2\uDEE0-\uDEE5\uDEE9\uDEEB-\uDEEC\uDEF0\uDEF3-\uDEF6]|\uD83E[\uDD10-\uDD1E\uDD20-\uDD27\uDD30\uDD33-\uDD3A\uDD3C-\uDD3E\uDD40-\uDD45\uDD47-\uDD4B\uDD50-\uDD5E\uDD80-\uDD91\uDDC0]))/g;
        let result = string.replace(regex, '').replace(/ /g, "");
        return result.trimLeft()
    }


    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        },
        validationSchema: Yup.object().shape({
            newPassword: Yup.string()
                // .min(4, appconstant.minPasswordValidation)
                .test("trim", "Please enter new password.",
                    (value) => {
                        value = value?.replace(/\s+/g, "")
                        if (value) value = removeEmojis(value)
                        return value?.trim()?.length > 0
                    })
                .min(8, 'Password must include 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric value, 1 special character and no spaces.')
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>/?]).+$/, "Password must include 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric value, 1 special character and no spaces."),

            oldPassword: Yup.string().required('Please enter old password.')
                .test("trim", 'Please enter old password.',
                    (value) => {

                        value = value?.replace(/ /g, "")
                        if (value) value = removeEmojis(value)
                        return value?.trim()?.length > 0
                    }),

            confirmPassword: Yup.string().required('Confirm new password must match with new password.')
                .test("trim", 'Confirm new password must match with new password.',
                    (value) => {
                        value = value?.replace(/ /g, "")
                        if (value) value = removeEmojis(value)
                        return value?.trim()?.length > 0
                    })
                .oneOf([Yup.ref("newPassword"), null], 'Confirm new password must match with new password')
        }),

        onSubmit: (values) => {
            dispatch(changePassword(values))
        }
    })


    useEffect(() => {
        document.title = 'Albastini';
        window.scrollTo(0, 0)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (

        <div>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>
                <Breadcrumb.Item>{appconstant.changepassword}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <div className="content-e">
                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                        <h6 className="text-white text-capitalize ps-3">{appconstant.changepassword}</h6>
                        <input type="text" className="search-bar" placeholder="Search" style={{ visibility: "hidden" }} />

                    </div>
                    <form noValidate onSubmit={formik.handleSubmit}>
                        <div className="wrapper_line">
                            <div className="form-group">
                                <label>{appconstant.Oldpassword}</label>
                                <input
                                    className='form-control'
                                    type='password'
                                    placeholder='Old Password'
                                    name='oldPassword'
                                    value={formik.values.oldPassword}
                                    onChange={(e) => {
                                        let data = e.target.value.trim();
                                        formik.setFieldValue("oldPassword", data);
                                    }}
                                />
                                {formik.errors.oldPassword ? (
                                    <div style={{ color: "red" }}>{formik.errors.oldPassword}</div>
                                ) : null}
                            </div>

                            <div className="form-group">
                                <label>{appconstant.NewPassword}</label>
                                <input
                                    className='form-control'
                                    type='password'
                                    placeholder='New Password'
                                    name='newPassword'
                                    value={formik.values.newPassword}
                                    onChange={(e) => {
                                        let data = e.target.value.trim();
                                        formik.setFieldValue("newPassword", data);
                                    }}
                                />
                                {formik.errors.newPassword ? (
                                    <div style={{ color: "red" }}>{formik.errors.newPassword}</div>
                                ) : null}
                            </div>

                            <div className="form-group">
                                <label>{appconstant.ConfirmNewPassword}</label>
                                <input
                                    className='form-control'
                                    type='password'
                                    placeholder='Confirm New Password'
                                    name='confirmPassword'
                                    value={formik.values.confirmPassword}
                                    onChange={(e) => {
                                        let data = e.target.value.trim();
                                        formik.setFieldValue("confirmPassword", data);
                                    }}
                                />
                                {formik.errors.confirmPassword ? (
                                    <div style={{ color: "red" }}>{formik.errors.confirmPassword}</div>
                                ) : null}
                            </div>
                            <div className="center-align">
                                <button type="submit" className="button-list">{appconstant.buttonupate}</button>
                                {/* <Link to="/login"><button type="submit" className="button-list">{appconstant.buttonupate}</button>
                            </Link> */}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    )
}
export default Changepassword;
