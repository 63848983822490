import { reduxConstant } from "../../constants/appconstant";

const initialState = {
  isLoading: false,
  shopList: [],
  pagination: {},
  itemDetail: {},
  shopData: [],
};

export const shopManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case reduxConstant.API_SHOP_LIST_LOAD:
      return { ...state, isLoading: true };
    case reduxConstant.API_SHOP_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shopList: action?.result?.data,
        pagination: action?.result?.pagination,
      };

    case reduxConstant.API_VIEW_SHOP_LOAD:
      return { ...state, isLoading: true };
    case reduxConstant.API_VIEW_SHOP_SUCCESS:
      return { ...state, isLoading: false, itemDetail: action?.result?.data };

    case reduxConstant.API_ADD_SHOP_ITEM_LOAD:
      return { ...state, isLoading: true };
    case reduxConstant.API_ADD_SHOP_ITEM_SUCCESS:
      return { ...state, isLoading: false };

    case reduxConstant.GET_SHOP_DATA_LOAD:
      return { ...state, isLoading: true };
    case reduxConstant.GET_SHOP_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        shopData: action.result?.data,
        pagination: action?.result?.pagination,
      };
    //Fail
    case reduxConstant.API_SHOP_MANAGEMENT_FAIL:
      return { ...state, isLoading: false };

    //default
    default:
      return { ...state };
  }
};
