import { reduxConstant } from "../../constants/appconstant"
import { put, call, takeLatest } from "@redux-saga/core/effects";

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { sendNotificationApi, notificationList, notificationDelete, notificationEdit } from "../Api/index";
import { navigate } from '../../utils/navigator'


function* sendNotification(action) {
    const data = yield call(sendNotificationApi, action.data);
    //for success
    if (data.status === 0) {
        yield put({
            type: reduxConstant.SEND_NOTIFICATION_SUCCESS,
            payload: data.result,
        });

        navigate('/notificationmanagement');
        // toast.dismiss()
        toast.success(data.result.message, { toastId: 1 });
    }
    else {
        //for failure
        // toast.dismiss()
        toast.error(data.result.message);
    }


}



function* listNotification(action) {
    const data = yield call(notificationList, action.data);
    //for success
    if (data.status === 0) {

        yield put({
            type: reduxConstant.NOTIFICATION_LIST_SUCCESS,
            list: data.result.data.data,
            pagination: data.result.data.paginationData
        });

        // toast.dismiss()
        //toast.success(data.result.message, { toastId: 1 });
    }
    else {
        //for failure
        // toast.dismiss()
        toast.error(data.result.message);
    }


}


function* deleteNotification(action) {
    const data = yield call(notificationDelete, action.data.payload);
    //for success
    if (data.status === 0) {
        yield put({
            type: reduxConstant.NOTIFICATION_DELETE_SUCCESS,
            id: action.data.id,
        });
        yield put({
            type: reduxConstant.NOTIFICATION_LIST_LOAD,
            data: action.data.list,
        });

        toast.dismiss()
        toast.success(data.result.message, { toastId: 1 });
    }
    else {
        //for failure
        // toast.dismiss()
        toast.error(data.result.message);
    }


}


function* editNotification(action) {
    const data = yield call(notificationEdit, action.data);
    //for success
    if (data.status === 0) {
        yield put({
            type: reduxConstant.NOTIFICATION_EDIT_SUCCESS,
            id: action.data.id,
        });
        action.navigate('/notificationmanagement')

        // toast.dismiss()
        toast.success(data.result.message, { toastId: 1 });
    }
    else {
        //for failure
        // toast.dismiss()
        toast.error(data.result.message);
    }


}





export default function* NotificationSaga() {
    yield takeLatest(reduxConstant.SEND_NOTIFICATION_LOAD, sendNotification);
    yield takeLatest(reduxConstant.NOTIFICATION_LIST_LOAD, listNotification);
    yield takeLatest(reduxConstant.NOTIFICATION_EDIT_LOAD, editNotification);
    yield takeLatest(reduxConstant.NOTIFICATION_DELETE_LOAD, deleteNotification);
}
