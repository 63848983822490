import React, { useEffect } from "react"
import { Link, useLocation } from "react-router-dom";
import appconstant from "../../themes/appconstant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from 'antd';
import images from "../../themes/appImage";
import { useDispatch, useSelector } from "react-redux";
import { viewShopAction } from "../../redux/Action/Auth";
import { reduxConstant } from '../../constants/appconstant'

const UsesrDetail = () => {

    const dispatch = useDispatch();
    const shopDetail = useSelector(x => x.shopManagementReducer?.itemDetail);
    const location = useLocation();

    useEffect(() => {
        
        dispatch(viewShopAction(location?.state))
        
        document.title = 'Albastini';
        window.scrollTo(0, 0)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    return (

        <div>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/shopmanagement"> {appconstant.ShopManagement} </Link></Breadcrumb.Item>
                <Breadcrumb.Item>{appconstant.viewShop}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <div className="content-e">
                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                        <h6 className="text-white text-capitalize ps-3">{appconstant.viewShop}</h6>
                        <input type="text" className="search-bar" placeholder="Search" style={{ visibility: "hidden" }} />

                    </div>

                    <div className="datacenter">
                        {console.log("shopDetail", shopDetail)}
                        <img alt="" src={shopDetail?.imageUrl ? `${reduxConstant.IMAGE_URL}/${shopDetail?.imageUrl}` : images.usermessage} style={{ width: '100px', Height: '200px' }} />


                    </div>
                    <div className="wrapper_line">

                        <div className="fields">
                            <label>{appconstant.itemName}</label>
                            <p>{shopDetail?.name ? shopDetail?.name?.charAt(0).toUpperCase() + shopDetail?.name?.slice(1) : 'N/A'}</p>
                        </div>
                        <div className="fields">
                            <label>{appconstant.cost}</label>
                            <p>{shopDetail?.cost ? shopDetail?.cost : 'N/A'}</p>
                        </div>

                    </div>
                </div>


            </div>
        </div>

    )
}
export default UsesrDetail;
