import React, { useEffect } from "react"
import { Link, useLocation } from "react-router-dom";
import appconstant from "../../themes/appconstant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from 'antd';
import images from "../../themes/appImage";
import { useDispatch, useSelector } from "react-redux";
import { viewUserAction } from "../../redux/Action/Auth";
import { reduxConstant } from '../../constants/appconstant'
import { Image } from 'antd';

const UsesrDetail = () => {

    useEffect(() => {
        document.title = 'Albastini';
        window.scrollTo(0, 0)
    }, [])
    const dispatch = useDispatch();
    const userDetail = useSelector(x => x.userManagementReducer?.userDetail);
    const location = useLocation();

    useEffect(() => {
        dispatch(viewUserAction(location?.state))
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (

        <div>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/usermanagement"> {appconstant.UserManagement} </Link></Breadcrumb.Item>
                <Breadcrumb.Item>{appconstant.viewUser}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <div className="content-e">
                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                        <h6 className="text-white text-capitalize ps-3">{appconstant.viewUser}</h6>
                        <input type="text" className="search-bar" placeholder="Search" style={{ visibility: "hidden" }} />

                    </div>
                    <div>
                        <div style={{ marginLeft: "20px" }}>

                            <Image
                                src={
                                    userDetail?.profileImage
                                        ?userDetail?.profileImage?.includes("https")?userDetail?.profileImage :`${reduxConstant.IMAGE_URL}/${userDetail?.profileImage}`
                                        : userDetail.avatar
                                            ? images[userDetail.avatar]
                                            : images.dummyUser
                                }
                                style={{
                                    width: "100px",
                                    height: "100px",
                                    borderRadius: "100%",

                                }}
                            />
                        </div>
                    </div>
                    <div className="wrapper_line">

                        <div className="fields">
                            <label>{appconstant.userName}</label>
                            <p>{userDetail?.userName ? userDetail?.userName : 'N/A'}</p>
                        </div>
                        <div className="fields">
                            <label>{appconstant.fullName}</label>
                            <p>{userDetail?.fullName ? userDetail?.userName?.charAt(0).toUpperCase() + userDetail?.userName?.slice(1) : 'N/A'}</p>
                        </div>

                        < div className="fields">
                            <label>{appconstant.EmailAddress}</label>
                            <p>{userDetail?.email ? userDetail?.email : 'N/A'}</p>
                        </div>
                        < div className="fields">
                            <label>{appconstant.coinBalance}</label>
                            <p>{userDetail?.coins ? userDetail?.coins : 'N/A'}</p>
                        </div>





                    </div>
                </div>


            </div>
        </div>

    )
}
export default UsesrDetail;
