import { msgConstant, reduxConstant } from "../../constants/appconstant";
import { put, call, takeLatest } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  editUserApi,
  updateVersionApi,
  versionDetailsApi,
  versionUpdateApi,
} from "../Api/index";

function* versionDetailsSaga(action) {
  try {
    const response = yield call(versionDetailsApi);
    if (response?.status === 0) {
      yield put({
        type: reduxConstant.API_VERSION_DETAILS_SUCCESS,
        result: response?.result?.data,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_VERSION_DETAILS_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_VERSION_DETAILS_FAIL });
  }
}


function* versionUpdateSaga(action) {
  try {
    const response = yield call(versionUpdateApi,action.data);
    if (response?.status === 0) {
      yield put({
        type: reduxConstant.API_UPDATE_VERSION_SUCCESS,
      });
      toast.success(response.result.message);
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_UPDATE_VERSION_FAIL });
    }
  } catch (error) {
    console.log(error);
    // toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_UPDATE_VERSION_FAIL });
  }
}


export default function* VersionManagementSaga() {
  yield takeLatest(reduxConstant.API_VERSION_DETAILS_LOAD, versionDetailsSaga);
  yield takeLatest(reduxConstant.API_UPDATE_VERSION_LOAD, versionUpdateSaga);
}
