import React, { useState, useEffect } from "react";
import { Input, InputNumber, Pagination } from "antd";
import images from "../../themes/appImage";
import { Link, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal } from "antd";
import appconstant from "../../themes/appconstant";
import { useDispatch, useSelector } from "react-redux";
import {
  userListAction,
  blockUserAction,
  userAddCoinAction,
} from "../../redux/Action/Auth";
import { reduxConstant } from "../../constants/appconstant";
import { removeEmojisForSearch } from "../../common/validation";

const UserManagement = () => {
  const dispatch = useDispatch();
  const [coinvalue, setCoinValue] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const [dataId, setDataId] = useState(null);
  const userListData = useSelector(
    (state) => state.userManagementReducer.userList.userList
  );
  const paginationData = useSelector(
    (state) => state.userManagementReducer.userList.paginationData
  );
  const loading = useSelector((state) => state.userManagementReducer.isLoading);
  const [isError, setError] = useState(null);

  const [searchParams, setSearchParams] = useSearchParams();
  const [payload, setPayload] = useState({
    offset: Number(searchParams.get("offset")) || 0,
    limit: Number(searchParams.get("limit")) || 10,
    sort: searchParams.get("sort") || null,
    order: Number(searchParams.get("order")) || null,
    search: searchParams.get("search") || "",
  });
  const [searchValue, setSearchValue] = useState(payload.search);

  const handleChange = (col, page) => {
    console.log("working");
    const tempPayload = { ...payload };

    if (col && col.columnKey) {
      tempPayload["sort"] = col?.columnKey || payload?.sort;
      tempPayload["order"] =
        col?.order === "ascend"
          ? 1
          : col?.order === "descend"
          ? -1
          : payload?.order;
    } else if (page) {
      tempPayload["offset"] = (page - 1) * 10;
    }

    setPayload({ ...tempPayload, search: searchValue });

    // Update query params
    const newParams = new URLSearchParams(tempPayload);
    setSearchParams(newParams);
    console.log("working2", searchValue, "sd");

    dispatch(userListAction({ ...tempPayload, search: searchValue }));
  };

  useEffect(() => {
    if (searchValue?.length <= 50) {
      const newParams = new URLSearchParams({
        ...payload,
        search: searchValue,
        offset: 0,
      });

      setSearchParams(newParams);
      dispatch(userListAction({ ...payload, search: searchValue, offset: 0 }));
    }
  }, [searchValue]);

  useEffect(() => {
    const newParams = new URLSearchParams(payload);
    setSearchParams(newParams);
  }, [payload, setSearchParams]);

  const handleSearch = (e) => {
    const serachData = removeEmojisForSearch(e?.target?.value);
    if (!serachData?.startsWith(" ")) {
      const newParams = new URLSearchParams({
        ...payload,
        search: e?.target?.value,
      });
      setSearchParams(newParams);
      setSearchValue(serachData);
    }
  };

  const blockModal = (currentUser) => {
    Modal.confirm({
      title: currentUser?.isBlock ? appconstant.unBlock : appconstant.block,
      content: currentUser?.isBlock
        ? appconstant.unblockUserWarning
        : appconstant.blockUserWarning,
      okText: "Yes",
      centered: true,
      cancelText: "No",
      onOk() {
        dispatch(blockUserAction(currentUser._id));
      },
      onCancel() {},
      maskClosable: true,
      className: "new-button",
    });
  };
  console.log(coinvalue, "coinvalue");
  const deleteModel = (data) => {
    setModalVisible(true);
    setDataId(data._id);
    // Modal.confirm({
    //   title: "Add Coins",
    //   content: (
    //     <div className="coins-input">
    //       <InputNumber
    //         className="coins-inputnumber"
    //         type="number"
    //         name="add-coins"
    //         placeholder="Add coins"
    //         value={coinvalue}
    //         onChange={(e) => {
    //           console.log(e,"asajdlaj");
    //           setCoinValue(e)
    //         }}
    //         min={1}
    //         max={1000}
    //       />
    //       {/* {isError
    //       ?
    //       <span>{isError}</span>
    //     :null} */}
    //     </div>
    //   ),
    //   okText: "Add",
    //   centered: true,
    //   cancelText: "Cancel ",
    //   onOk() {
    //       dispatch(userAddCoinAction({ userId: data?._id, coins: coinvalue }));
    //   },
    //   onCancel() {},
    //   maskClosable: true,
    //   className: "new-button",
    // });
  };

  const dataSource = userListData?.map((e) => {
    console.log(e, "asdjkhas");
    return {
      _id: e?._id,
      email: e?.email,
      fullName: e?.fullName,
      userName: e?.userName,
      profileImage: e?.profileImage,
      isBlock: e?.isBlock,
      avatar: e?.avatar,
      coins: e?.coins,
    };
  });

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (value, item, index) => {
        return Number(searchParams.get("offset")) + index + 1;
      },
    },

    {
      title: "Profile Image",
      dataIndex: "profile",
      key: "profile",
      sorter: false,
      render: (title, data, index) => {
        console.log(data, "avatar6");
        return (
          <>
            {
              <img
                alt=""
                src={
                  data?.profileImage
                    ? data?.profileImage?.includes("https")
                      ? data?.profileImage
                      : `${reduxConstant.IMAGE_URL}/${data?.profileImage}`
                    : data?.avatar
                    ? images[data.avatar]
                    : images.dummyUser
                }
              ></img>
            }
          </>
        );
      },
    },

    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (title, data, index) => <>{data?.userName || "N/A"}</>,
    },
    {
      title: "Full Name ",
      dataIndex: "fullname",
      key: "fullname",
      sorter: true,
      render: (title, data, index) => (
        <>
          {data?.fullName?.charAt(0).toUpperCase() + data?.fullName?.slice(1) ||
            "N/A"}
        </>
      ),
    },

    {
      title: "Email Address ",
      dataIndex: "email",
      key: "email",
      sorter: true,
      render: (title, data, index) => <>{data?.email || "N/A"}</>,
    },
    {
      title: appconstant.coinBalance,
      dataIndex: "coins",
      key: "coins",
      render: (title, data, index) => <>{title || "N/A"}</>,
    },

    // {
    //   title: "Number of Coins",
    //   dataIndex: "numberOfCoins",
    //   key: "numberOfCoins",
    //   render: (title, data, index) => <>{data?.coins || "N/A"}</>,
    // },

    {
      title: "Actions",
      dataIndex: "Posted",
      key: "Posted",
      render: (title, data, index) => {
        return (
          <div>
            <Link to={`/userdetail`} state={data?._id}>
              <button className="view-btn" type="submit">
                {appconstant.view}
              </button>
            </Link>
            <Link to="/edituserdetail" state={data?._id}>
              <button className="edit-btn" type="submit">
                {appconstant.edit}
              </button>
            </Link>
            <button
              className="block-btn"
              type="submit"
              onClick={() => {
                blockModal(data);
              }}
            >
              {data?.isBlock ? appconstant.unBlock : appconstant.block}
            </button>

            <button
              className="add-coins"
              type="submit"
              onClick={() => {
                deleteModel(data);
              }}
            >
              {appconstant.addCoins}
            </button>

            <Link to="/economylogs" state={data?._id}>
              <button className="my-btn" type="submit">
                Coins History
              </button>
            </Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(userListAction(payload));
    document.title = "Albastini";
    return () => Modal.destroyAll();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.UserManagement}</Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.UserManagement}
            </h6>
            <input
              type="text"
              onChange={handleSearch}
              className="iocn-search  search-bar"
              placeholder="Search"
              value={searchValue}
            />
          </div>

          <Table
            className="unser-manage-table"
            dataSource={dataSource?.map((item, index) => ({
              ...item,
              key: index,
            }))}
            columns={columns}
            showSorterTooltip={false}
            loading={loading}
            pagination={false}
            onChange={(x, y, col) => handleChange(col)}
          />
          <Pagination
            showSizeChanger={false}
            showTitle={false}
            className="pagination-wrapper"
            total={paginationData?.totalCount}
            current={paginationData?.currentPage}
            onChange={(page) => handleChange(null, page)}
          />
        </div>
        {isModalVisible ? (
          <>
            <Modal
              visible={isModalVisible}
              title={"Add Coins"}
              onCancel={() => {
                setModalVisible(false);
              }}
              okButtonProps={{
                disabled: coinvalue ? false : true,
              }}
              cancelText={"Cancel"}
              cancelButtonProps={{
                className: "addCoinsCancelbtn",
              }}
              okText={"Add"}
              onOk={() => {
                dispatch(
                  userAddCoinAction({ userId: dataId, coins: coinvalue })
                );
                setTimeout(() => {
                  setModalVisible(false);
                  setCoinValue();
                }, 500);
              }}
            >
              <>
                <div className="coins-input">
                  <InputNumber
                    className="coins-inputnumber"
                    type="number"
                    name="add-coins"
                    placeholder="Add coins"
                    value={coinvalue}
                    formatter={(value) => `${value}`.replace(/[^\d]/g, "")} // Restrict input to numeric values only
                    onChange={(value) => {
                      if (value == "e") {
                        console.log(value);
                      } else {
                        console.log("value", value);
                        setCoinValue(value);
                      }
                    }}
                    min={1}
                    max={1000}
                  />
                </div>
              </>
            </Modal>
          </>
        ) : null}
      </div>
    </div>
  );
};
export default UserManagement;
