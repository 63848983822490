import React, { useEffect, useState } from "react";

const Delete = () => {


  return (
    <div style={{
      padding: 25,
      paddingBottom: 300,
    }}>
      <div className="bg-col pb-5">
        <div className="bg-img-pol">
          <section>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="priv-pol">
                    <h1>Follow the below steps to delete an account in the game:-</h1>
                  </div>
            
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-12">
                  <div className="priv-pol-ph">
                    <p>
                      <span>1.</span>Login to your account.
                    </p>
                    <p>
                      <span>2.</span>Go to settings (Upper right corner).
                    </p>
                    <p>
                      <span>3.</span>Click on the "Delete Account" button (lower left corner). After clicking "Yes" on the popup, all game data will be deleted permanently.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </div>
    </div>
  );
};
export default Delete;

