import React from "react";
import { Layout } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons";


const { Header } = Layout;



const HeaderAdmin = (props) => {
    return (
        <div>
            <Header className="site-layout-sub-header-background" style={{ padding: 0 }}>
                <div className="iocns">
                    <FontAwesomeIcon icon={faBars} className="lite-text" onClick={props.handlewClick} />
                </div>


            </Header>
        </div>

    )
}
export default HeaderAdmin;