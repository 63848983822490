import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from 'antd';
import appconstant from "../../themes/appconstant";
import ModalPop from "../../common/modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { sendNotification } from '../../redux/Action/Auth'
import { useDispatch } from 'react-redux'


const AddNotification = () => {
    const dispatch = useDispatch()

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false); // State to track submission status

    const formik = useFormik({
        initialValues: {
            title: "",
            description: ""
        },
        validationSchema: Yup.object().shape({
            title: Yup.string()
                .required("Please enter title.")
                .test("trim", "Please enter valid title.",
                    (value) => {
                        value = value?.replace(/\s+/g, "")
                        return ! /[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(value)
                    })
                .min(3, "Title must contain at least 3 characters.")
                .max(255),
            description: Yup.string()
                .required("Please enter description.")
                .test("trim", "Please enter valid description.",
                    (value) => {
                        value = value?.replace(/\s+/g, "")
                        return ! /[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(value)
                    })
                .min(3, "Description must contain at least 3 characters.")
                .max(255),
        }),

        onSubmit: async (values) => { // Make onSubmit function asynchronous
            setIsSubmitting(true); // Set isSubmitting to true before dispatching values
            await dispatch(sendNotification(values)); // Dispatch values
            setIsSubmitting(false); // Reset isSubmitting after dispatching
            setIsModalVisible(true); // Set modal visible after dispatching values
        }
    })


    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const successBtn = (
        <>
            <div className="forgot-text-btn">
                <Link to='/usermanagement'>
                    <button className='forgot_text'>
                        Ok
                    </button>
                </Link>
            </div>
        </>
    )

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const fragment = (
        <>
            <div className="success-deta">
                User details updated successfully.</div>
        </>
    )

    useEffect(() => {
        document.title = 'Albastini';
        window.scrollTo(0, 0)
    }, [])




    return (

        <div>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>

                <Breadcrumb.Item><Link to="/notificationmanagement">{appconstant.notifcationManagement} </Link></Breadcrumb.Item>

                <Breadcrumb.Item>{appconstant.addNotification}</Breadcrumb.Item>

            </Breadcrumb>



            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <div className="content-e butoo-pa">
                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                        <h6 className="text-white text-capitalize ps-3">{appconstant.addNotification}</h6>
                    </div>
                    {/* <div className="wrapper_line ">
                        <h2>Edit User Details</h2>
                    </div> */}


                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                        <h6 className="text-white text-capitalize ps-3 mt-title">{appconstant.titile}</h6>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="key">
                            <input
                                className='form-control'
                                type='text'
                                placeholder='Title'
                                name="title"
                                onChange={(e) => {
                                    let data = (e.target.value).trimStart()
                                    formik.setFieldValue('title', data)
                                }}
                                maxLength={255}
                                value={formik.values.title}
                            />
                            {formik.errors.title ? (
                                <div style={{ color: "red" }}>{formik.errors.title}</div>
                            ) : null}
                        </div>


                        <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                            <h6 className="text-white text-capitalize ps-3 mt-title">{appconstant.description}</h6>
                        </div>
                        <div className="about-us">
                            <textarea rows="4"
                                cols="50" placeholder="Description..."
                                name="description"
                                onChange={(e) => {
                                    const data = (e.target.value).trimStart()
                                    formik.setFieldValue('description', data)
                                }}
                                maxLength={255}
                                value={formik.values.description}
                            ></textarea>
                            {formik.errors.description ? (
                                <div style={{ color: "red" }} >{formik.errors.description}</div>
                            ) : null}
                        </div>



                        <div className='up-btn'>
                            <button type="submit" className="button-list">{appconstant.add}  </button>
                        </div>
                    </form>
                </div>

            </div>
            
        </div>

    )
}
export default AddNotification
