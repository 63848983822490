import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb } from "antd";
import appconstant from "../../themes/appconstant";
import { useDispatch, useSelector } from "react-redux";
import { getErrorListAction, getGameListAction } from "../../redux/Action/Auth";

import { removeEmojisForSearch } from "../../common/validation";

import moment from "moment";

const ErrorLogs = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gameListData = useSelector(
    (state) => state.gameManagementReducer.gameList.list
  );

  const errorList = useSelector(
    (state) => state.gameManagementReducer.errorList.list
  );

  const paginationData = useSelector(
    (state) => state.gameManagementReducer.errorList.paginationData
  );

  const loading = useSelector((state) => state.gameManagementReducer.isLoading);

  const [searchParams, setSearchParams] = useSearchParams();
  const [payload, setPayload] = useState({
    offset: Number(searchParams.get("offset")) || 0,
    limit: Number(searchParams.get("limit")) || 10,
    sort: searchParams.get("sort") || null,
    order: Number(searchParams.get("order")) || null,
    search: searchParams.get("search") || "",
  });
  const [searchValue, setSearchValue] = useState(payload.search);

  const handleChange = (col, page) => {
    const tempPayload = { ...payload };

    if (col && col.columnKey) {
      tempPayload["sort"] = col?.columnKey || payload?.sort;
      tempPayload["order"] =
        col?.order === "ascend"
          ? 1
          : col?.order === "descend"
          ? -1
          : payload?.order;
    } else if (page) {
      tempPayload["offset"] = (page - 1) * 10;
    }

    setPayload({ ...tempPayload, search: searchValue });

    const newParams = new URLSearchParams({
      ...tempPayload,
      search: searchValue,
    });
    setSearchParams(newParams);

    dispatch(getErrorListAction({ ...tempPayload, search: searchValue }));
  };

  useEffect(() => {
    if (searchValue?.length <= 50) {
      dispatch(
        getErrorListAction({ ...payload, search: searchValue, offset: 0 })
      );
    }
  }, [searchValue]);

  useEffect(() => {
    const newParams = new URLSearchParams(payload);
    setSearchParams(newParams);
  }, [payload, setSearchParams]);

  const handleSearch = (e) => {
    const serachData = removeEmojisForSearch(e?.target?.value);
    if (!serachData?.startsWith(" ")) {
      const newParams = new URLSearchParams({
        ...payload,
        search: e?.target?.value,
        offset: 0,
      });
      setSearchParams(newParams);
      setSearchValue(serachData);
    }
  };

  const dataSource = errorList?.map((e) => {
    return {
      _id: e?._id,
      game_id: e?._id,
      errorType: e?.errorType,
      error: e?.error,
      controller: e?.controller,
      userName: e?.user?.userName,
      tournament: e?.tournament?.name,
      createdAt: e?.createdAt,
    };
  });
  function capitalizeFirstLetter(val) {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const initialPayload = {
      offset: Number(queryParams.get("offset")) || 0,
      limit: Number(queryParams.get("limit")) || 10,
      sort: queryParams.get("sort") || null,
      order: Number(queryParams.get("order")) || null,
      search: queryParams.get("search") || "",
    };
    setPayload(initialPayload); // set the payload from query params
    setSearchValue(initialPayload.search); // set search value from query params
    dispatch(getErrorListAction(initialPayload)); // fetch data based on initial payload
  }, []);

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      align: "left",
      render: (value, item, index) =>
        (paginationData?.currentPage - 1) * 10 + (index + 1),
    },

    {
      title: "User Name",
      dataIndex: "user",
      key: "user",
      sorter: false,
      align: "left",
      render: (title, data, index) => (
        <>
          {console.log(data, "dd")}

          {data?.userName ? data?.userName : "N/A"}
        </>
      ),
    },

    {
      title: "Error Type",
      dataIndex: "errorType",
      key: "errorType",
      align: "left",
      sorter: true,
      render: (title, data, index) => (
        <>{capitalizeFirstLetter(data?.errorType) || "N/A"}</>
      ),
    },
    {
      title: "Error",
      dataIndex: "error",
      key: "error",
      align: "left",
      sorter: false,
      render: (title, data, index) => (
        <>{capitalizeFirstLetter(data?.error) || "N/A"}</>
      ),
    },
    {
      title: "Error Info",
      dataIndex: "controller",
      key: "controller",
      align: "left",
      sorter: false,
      render: (title, data, index) => <>{data?.controller || "N/A"}</>,
    },

    {
      title: "Tournament Name",
      dataIndex: "tournament",
      key: "tournament",
      align: "left",
      sorter: false,
      render: (title, data, index) => <>{data?.tournament || "N/A"}</>,
    },

    {
      title: "Date & Time",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      render: (title, data, index) => (
        <>{moment(data?.createdAt).format("DD/MM/YYYY HH:mm") || "N/A"}</>
      ),
    },

    {
      title: "Actions",
      dataIndex: "Posted",
      key: "Posted",
      render: (value, item) => (
        <div>
          <button
            className="edit-btn"
            type="submit"
            onClick={() =>
              navigate("/viewErrorLogs", { state: { data: item?._id } })
            }
          >
            view
          </button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Error Logs</Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">Error Logs</h6>
            <input
              type="text"
              onChange={handleSearch}
              className="iocn-search  search-bar"
              placeholder="Search"
              value={searchValue}
            />
          </div>

          <Table
            className="unser-manage-table"
            dataSource={dataSource?.map((item, index) => ({
              ...item,
              key: index,
            }))}
            columns={columns}
            showSorterTooltip={false}
            loading={loading}
            pagination={false}
            onChange={(x, y, col) => handleChange(col)}
          />
          <Pagination
            showTitle={false}
            className="pagination-wrapper"
            showSizeChanger={false}
            total={paginationData?.totalCount}
            current={paginationData?.currentPage}
            onChange={(page) => handleChange(null, page)}
          />
        </div>
      </div>
    </div>
  );
};
export default ErrorLogs;
