import React, { useEffect, useState } from "react";
import { Image as ImageTag } from "antd";
import { Link } from "react-router-dom";
import appconstant from "../../themes/appconstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from "antd";
import Textfiled from "../../common";
import { useDispatch, useSelector } from "react-redux";
import { addShopDataAction } from "../../redux/Action/Auth";

const CreateTheme = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.tournamentReducer.loading);
  const [imageURL, setImageURL] = useState(null);
  const [themeNameEng, setThemeNameEng] = useState("");
  const [themeName, setThemeName] = useState("");
  const [price, setPrice] = useState("");
  const [image, setImage] = useState(null);
  const [themeNameEngError, setThemeNameEngError] = useState("");
  const [themeNameError, setThemeNameError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [imageError, setImageError] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      if(file.type == "image/png"){
      handleImageChange(file)
        .then((e) => {
          console.log("promise", e);
          setImageError("");
          setImage(file);
          const reader = new FileReader();
          reader.onloadend = () => {
            setImageURL(reader.result);
          };
          reader.readAsDataURL(file);
        })
        .catch((err) => {
          setImageError("Image height must be less then 3288 px and width must be less then 1720 px.");
        });
      }
      else{
        setImageError("Image must be in PNG format.");
      }
    }
  };

  useEffect(() => {
    document.title = "Albastini";
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Clear previous errors
    setThemeNameError("");
    setThemeNameEngError("");
    setPriceError("");
    setImageError("");

    // Validation
    let isValid = true;
    if (!themeNameEng.trim()) {
      setThemeNameEngError("Please enter theme name (English).");
      isValid = false;
    } else if (themeNameEng.trim().length < 3) {
      setThemeNameEngError("Name must contain at least 3 characters.");
      isValid = false;
    } else if (themeNameEng.trim().length > 255) {
      setThemeNameEngError("Name must be less than 25 characters.");
      isValid = false;
    }
    if (!themeName.trim()) {
      setThemeNameError("Please enter theme name (Swahili).");
      isValid = false;
    } else if (themeName.trim().length < 3) {
      setThemeNameError("Name must contain at least 3 characters.");
      isValid = false;
    } else if (themeName.trim().length > 255) {
      setThemeNameError("Name must be less than 25 characters.");
      isValid = false;
    }

    if (!price.trim()) {
      setPriceError("Please enter price.");
      isValid = false;
    } else if (isNaN(price) || (!parseInt(price)<0) ) {
      setPriceError("Price must be a numeric value.");
      isValid = false;
    }  else if (!/^\d+$/.test(price)) {
      setPriceError("Price must be a numeric value.");
      isValid = false;
    } else if (price<0) {
      setPriceError("Price must be a numeric value.");
    }

    if (!image) {
      setImageError("Please select an image.");
      isValid = false;
    } else if (image?.size > 1024 * 1024 * 2) {
      setImageError("File size too large. Max size is 2MB.");
      isValid = false;
    } else if (!["image/png"].includes(image.type)) {
      setImageError("Unsupported file format. Only PNG files allowed.");
      isValid = false;
    }

    if (isValid) {
      const formData = new FormData();
      formData.append("nameEn", themeNameEng.trimStart());
      formData.append("nameSw", themeName.trimStart());
      formData.append("type", "skins");
      formData.append("cost", parseInt(price));
      formData.append("profileImage", image);

      dispatch(addShopDataAction(formData));
    }
  };
  const handleImageChange = (event) => {
    var _URL = window.URL || window.webkitURL;
    console.log(event);
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = _URL.createObjectURL(event);
      image.onload = () => {
        ( image.width <= 1720 && image.height <= 3288 )
          ? resolve("true")
          : reject("false");
      };
    });
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/shopmanagement"> {appconstant.ShopManagement} </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.createTheme}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <h6 className="text-white text-capitalize ps-3">
          {appconstant.createTheme}
        </h6>
        <form className="content-e" onSubmit={handleSubmit}>
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <input
              type="text"
              className="search-bar"
              placeholder="Search"
              style={{ visibility: "hidden" }}
            />
          </div>
          <div className="wrapper_line">
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
                cursor:'pointer'
              }}
            >
              {/* File picker input */}
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="file-input"
              />
              {!imageURL ? (
                <label
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems:'center',
                    flexDirection: "column",
                  }}
                  htmlFor="file-input"
                >
                  <div
                    style={{
                      height: 80,
                      width: 80,
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: "50%",
                      cursor:'pointer',
                      border: "2px solid black",
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ height: 50, width: 50, marginTop: 12 }}
                      icon={faPlus}
                    />
                  </div>

                  {/* <p style={{ marginTop: 5 }}> Upload Emoji</p> */}
                </label>
              ) : (
                <div style={{ flexDirection: "column" }}>
                  <ImageTag
                    src={imageURL}
                    alt="Uploaded"
                    style={{
                      maxWidth: "100%",
                      height: "60px",
                      marginRight: 10,
                    }}
                    className="ms-3" // Adding margin to separate from text
                  />
                  <label htmlFor="file-input" style={{marginLeft:5}}>
                    <FontAwesomeIcon icon={faPlus} /> Change Theme
                  </label>
                </div>
              )}
            </div>
            <div
              style={{
                width: "100%",
                marginTop: 20,
                justifyContent: "center",
                display: "flex",
              }}
            >
              {imageError && (
                <div className="form-validation">{imageError}</div>
              )}
            </div>
            <div className="form-group">
              <label>{appconstant.ThemeName}</label>
              <Textfiled
                className="form-control"
                placeholder="Theme Name"
                value={themeNameEng}
                onChange={(e) => setThemeNameEng(e.target.value)}
                maxLength={25}
              />
              <div
                style={{
                  width: "100%",
                  marginTop: 20,

                  display: "flex",
                }}
              >
                {" "}
                {themeNameEngError && (
                  <div className="form-validation">{themeNameEngError}</div>
                )}
              </div>
            </div>
            <div className="form-group">
              <label>{appconstant.ThemeNameSwahili}</label>
              <Textfiled
                className="form-control"
                placeholder="Theme Name"
                value={themeName}
                onChange={(e) => setThemeName(e.target.value)}
                maxLength={25}
              />
              <div
                style={{
                  width: "100%",
                  marginTop: 20,

                  display: "flex",
                }}
              >
                {" "}
                {themeNameError && (
                  <div className="form-validation">{themeNameError}</div>
                )}
              </div>
            </div>
            <div className="form-group">
              <label>{appconstant.Price}</label>
              <Textfiled
                className="form-control"
                placeholder="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                maxLength={3}
              />
              <div
                style={{
                  width: "100%",
                  marginTop: 20,
                  display: "flex",
                }}
              >
                {priceError && (
                  <div className="form-validation">{priceError}</div>
                )}
              </div>
            </div>
            <div className="center-align">
              <button disabled={loading} type="submit" className="button-list">
                {appconstant.createbtn}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default CreateTheme;
