import React, { useEffect, useState } from "react";
import { Table, Breadcrumb, Input, Pagination, Card } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import appconstant from "../../themes/appconstant";
import { Activity, Activity2 } from "../../common";
import { useDispatch, useSelector } from "react-redux";
import { tournamentlogsAction } from "../../redux/Action/Auth";

const TournamentLogs = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate(); // <-- useNavigate hook for navigation
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const { tournamentList } = useSelector((x) => x.tournamentReducer);
  

  useEffect(() => {
    dispatch(
      tournamentlogsAction({
        tournamentId: location.state,
      })
    );
  }, [dispatch, location.state]);

  const activity = tournamentList && tournamentList?.playerenterLogs?.length > 0 && tournamentList?.playerenterLogs?.map((tournament, index) => {
    return [
      {
        id: tournament._id,
        date: new Date(tournament.player1joinTime).toLocaleString(),
        activity: `${tournament.player || "N/A"} Joined`,
      },
    ];
  }).flat();

  const activityMaking = tournamentList && tournamentList?.tournamentData?.length > 0 && tournamentList.tournamentData?.map((tournament, index) => ({
    id: tournament._id,
    date: new Date(tournament.startTime).toLocaleDateString(),
    activity: `Round ${tournament.round} Match between ${tournament.player1 || "N/A"} and ${tournament.player2 || "N/A"}`,
  }));

  const activityExit = tournamentList && tournamentList?.tournamentData?.length > 0 && tournamentList?.tournamentData
    .filter((tournament) => tournament.selfExitTime)
    .map((tournament, index) => ({
      id: tournament._id,
      date: new Date(tournament.selfExitTime).toLocaleString(),
      activity: `${tournament.selfExitPlayer} Exit`,
    }));

  const handleViewClick = (id) => {
    navigate("/gamelog" , {
      state : id
    }); 
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>

        <Breadcrumb.Item>
          <Link to="/tournamentmanagement">
            {appconstant.tournamentManagement}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Tournament Logs</Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">Tournament Logs</h6>
          </div>

          <Card className="scroll-card">
            <div
              style={{ marginBottom: 15, marginTop: 5, fontSize: 16, fontWeight: "600" }}
            >
              Player Enter Logs
            </div>
            <div className="user-activity">
              <ul className="user-list">
                {activity && activity?.length > 0 && activity.map((v, index) => (
                  <Activity2 key={index} id={v.id} date={v.date} activity={v.activity} onViewClick={handleViewClick} />
                ))}
              </ul>
            </div>

            <div
              style={{ marginBottom: 15, marginTop: 5, fontSize: 16, fontWeight: "600" }}
            >
              Match Making Logs
            </div>
            <div className="user-activity">
              <ul className="user-list">
                {activityMaking && activityMaking?.length > 0 && activityMaking.map((v, index) => (
                  <Activity key={index} id={v.id} date={v.date} activity={v.activity || "N/A"} onViewClick={handleViewClick} />
                ))}
              </ul>
            </div>

            <div
              style={{ marginBottom: 15, marginTop: 5, fontSize: 16, fontWeight: "600" }}
            >
              Player Exit Logs
            </div>
            <div className="user-activity">
              <ul className="user-list">
                {activityExit && activityExit?.length > 0 && activityExit.map((v, index) => (
                  <Activity2 key={index} id={v.id} date={v.date} activity={v.activity} onViewClick={handleViewClick} />
                ))}
              </ul>
            </div>
            
            <div style={{fontSize:28, display:'flex'}}>Winner:- {(tournamentList?.winner || "N/A")+(tournamentList.winnerCoins?" ("+"Coins Win : "+tournamentList.winnerCoins?.toFixed(2)+")" :"")}</div>
            <div style={{fontSize:28, display:'flex'}}>First Runner Up:- {(tournamentList?.runnerUp || "N/A") +(tournamentList.runnerUpCoins?" ("+"Coins Win : "+tournamentList.runnerUpCoins?.toFixed(2)+")":"")}</div>
            <div style={{fontSize:28, display:'flex'}}>Second Runner Up:- {(tournamentList?.thirdPlace || "N/A") +(tournamentList.thirdPlaceCoins?" ("+"Coins Win : "+tournamentList.thirdPlaceCoins?.toFixed(2)+")":"")}</div>

          </Card>
        </div>
      </div>
    </div>
  );
};

export default TournamentLogs;
