import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal } from 'antd';
import appconstant from "../../themes/appconstant";
import { notificationList, notificationDelete } from '../../redux/Action/Auth';
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { removeEmojisForSearch } from "../../common/validation";

const NotificationManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.notificationReducer.list);
  const pagination = useSelector((state) => state.notificationReducer.pagination);
  const loading = useSelector((state) => state.notificationReducer.loading);
  
  // Default search state
  const [search, setSearch] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const [payload, setPayload] = useState({
    offset: Number(searchParams.get('offset')) || 0,
    limit: Number(searchParams.get('limit')) || 10,
    sort: searchParams.get('sort') || null,
    order: Number(searchParams.get('order')) || null,
    search: searchParams.get('search') || "",
  });
  const [searchValue, setSearchValue] = useState(payload.search);

  useEffect(() => {
    const params = {
      ...payload,
      search: searchValue,
    };
    setSearchParams(new URLSearchParams(params));
    dispatch(notificationList(params));
  }, [payload, searchValue]);

  const handleSearch = (e) => {
    const searchData = removeEmojisForSearch(e?.target?.value);
    if (!searchData?.startsWith(" ")) {
      setSearchValue(searchData);
      setPayload((prev) => ({
        ...prev,
        search: searchData,
        offset: 0,
      }));
    }
  };

  const handleChange = (col, page) => {
    const tempPayload = { ...payload };

    if (col && col.columnKey) {
      tempPayload["sort"] = col?.columnKey || payload?.sort;
      tempPayload["order"] = col?.order === "ascend" ? 1 : col?.order === "descend" ? -1 : payload?.order;
    } else if (page) {
      tempPayload["offset"] = (page - 1) * 10;
    }

    setPayload(tempPayload);
  };

  const handleTableChange = (paginationChange, _, sortChange) => {
    const offset = (paginationChange.current - 1) * 10;
    const limit = 10;
    const sort = sortChange?.columnKey || null;
    const order = sortChange?.order === "ascend" ? 1 : sortChange?.order === "descend" ? -1 : null;

    setPayload({
      ...payload,
      offset,
      limit,
      sort,
      order,
    });
  };

  const disableModel = (record) => {
    Modal.confirm({
      title: "Delete",
      content: `Are you sure, you want to delete this notification?`,
      okText: 'Yes',
      centered: true,
      cancelText: 'No',
      onOk() {
        dispatch(notificationDelete({ payload: { id: record }, list: { offset: payload.offset, limit: payload.limit, search: payload.search } }));
      },
      onCancel() {},
      maskClosable: true,
      className: "new-button",
    });
  };

  const dataSource = data.map((e, index) => {
    return {
      _id: e._id,
      title: e.title,
      description: e.description,
      key: index,
    };
  });

  const columns = [
    {
      title: 'Sr. No.',
      dataIndex: 'srno',
      key: 'srno',
      render: (value, item, index) => ((payload.offset / 10) * 10) + (index + 1),
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      sorter: true,
    },
    {
      title: 'Actions',
      dataIndex: 'Posted',
      key: 'Posted',
      render: (value, item) => (
        <div>
          <button className="edit-btn" type="submit" onClick={() => navigate('/editNotification', { state: { data: item } })}>
            {appconstant.edit}
          </button>
          <button className="block-btn" type="submit" onClick={() => disableModel(item._id)}>
            {appconstant.delete}
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    document.title = 'Albastini';
  }, []);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.notifcationManagement}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">{appconstant.notifcationManagement}</h6>
            <input
              type="text"
              className="iocn-search  search-bar"
              placeholder="Search"
              value={searchValue}
              onChange={handleSearch}
            />
          </div>
          <div>
            <Link to="/addnotification">
              <button type="submit" className="button-list">{appconstant.addNotification}</button>
            </Link>
          </div>
          <Table
            dataSource={dataSource}
            columns={columns}
            showSorterTooltip={false}
            pagination={{ total: pagination?.totalCount || 0, current: (payload.offset / 10) + 1, showSizeChanger: false }}
            onChange={handleTableChange}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default NotificationManagement;
