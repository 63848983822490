import { reduxConstant } from "../../constants/appconstant"
import { put, call, takeLatest } from "@redux-saga/core/effects";

import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { LoginApi, forgotApi, dashboardApi, logoutApi, changePasswordApi, resetPasswordApi, linkValidApi } from "../Api/index";
import { navigate } from '../../utils/navigator'


function* login(action) {
    const data = yield call(LoginApi, action.data);
    //for success
    if (data.status === 0) {
        localStorage.setItem("token", data.result.data.token);
        yield put({
            type: reduxConstant.LOGIN_SUCCESS,
            payload: data.result,
        });

        navigate('/dashboard');
        toast.dismiss()
        toast.success(data.result.message, { toastId: 1 });
    }
    else {
        //for failure
        // toast.dismiss()
        toast.dismiss()
        toast.error(data.result.message);
    }


}


function* forgot(action) {
    const data = yield call(forgotApi, action.data);
    //for success
    if (data.status === 0) {
        yield put({
            type: reduxConstant.FORGOT_SUCCESS,
            payload: data.result,
        });
        // toast.dismiss()
        toast.success(data.result.message, { toastId: 1 });
        navigate('/login')
    }
    else {
        //for failure
        toast.dismiss()
        toast.error(data.result.message);
    }
}



function* dashboard(action) {
    const data = yield call(dashboardApi);

    //for success
    if (data.status === 0) {
        yield put({
            type: reduxConstant.DASHBOARD_SUCCESS,
            payload: data.result.data,
        });
        //toast.dismiss()
        //toast.success(data.result.message, { toastId: 1 });
    }
    else {
        //for failure
        toast.dismiss()
        toast.error(data.result.message);
    }
}


function* logout(action) {
    const data = yield call(logoutApi);

    //for success
    if (data.status === 0) {
        yield put({
            type: reduxConstant.LOGOUT_SUCCESS,
            payload: data.result,
        });
        toast.dismiss()
        toast.success(data.result.message, { toastId: 1 });
        navigate('/login')
        localStorage.clear()
    }
    else {
        //for failure
        toast.dismiss()
        toast.error(data.result.message);
    }
}

function* changePassword(action) {
    const data = yield call(changePasswordApi, action.data);

    //for success
    if (data.status === 0) {
        localStorage.clear()
        // yield put({
        //     type: reduxConstant.DASHBOARD_SUCCESS,
        //     payload: data.result.data,
        // });
        //toast.dismiss()
        toast.success(data.result.message, { toastId: 1 });
        navigate('/login')
    }
    else {
        //for failure
        toast.dismiss()
        toast.error(data.result.message);
    }
}

function* resetPassword(action) {
    const data = yield call(resetPasswordApi, action.data);

    //for success
    if (data.status === 0) {
        console.log('data', data)
        yield put({
            type: reduxConstant.RESET_PASSWORD_SUCCESS,
            payload: data.result.data,
        });
        //toast.dismiss()
        toast.success(data.result.message, { toastId: 1 });
        navigate('/login')
    }
    else {
        //for failure
        toast.dismiss()
        toast.error(data.result.message);
    }
}


function* linkValid(action) {
    const data = yield call(linkValidApi, action.data);

    //for success
    if (data.status === 0) {
        console.log('data', data)
        yield put({
            type: reduxConstant.FORGOT_LINK_SUCCESS,
            payload: data.result.data,
        });
        //toast.dismiss()
        // toast.success(data.result.message, { toastId: 1 });
        // navigate('/login')
    }
    else {
        //for failure
        // toast.dismiss()
        // toast.error(data.result.message);
    }
}



export default function* rootAuthSaga() {
    yield takeLatest(reduxConstant.LOGIN_LOAD, login);
    yield takeLatest(reduxConstant.FORGOT_LOAD, forgot);
    yield takeLatest(reduxConstant.DASHBOARD_LOAD, dashboard);
    yield takeLatest(reduxConstant.LOGOUT_LOAD, logout);
    yield takeLatest(reduxConstant.CHANGE_PASSWORD_LOAD, changePassword);
    yield takeLatest(reduxConstant.RESET_PASSWORD_LOAD, resetPassword);
    yield takeLatest(reduxConstant.FORGOT_LINK_LOAD, linkValid);

}
